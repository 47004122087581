import { Component, OnInit } from '@angular/core';

import { TextsService } from '../../services/texts/texts.service';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public texts: TextsService;

  constructor(private contactService: ContactService) {
    this.texts = new TextsService();
  }

  ngOnInit() {
    this.contactService.getContact()
      .subscribe(response => { this.texts.texts = response.texts
                             });
  }

}
