import { Injectable } from "@angular/core";
import { PrimeNGConfig } from "primeng/api";
import { TextsService } from "./texts.service";

@Injectable({
    providedIn: "root",
})
export class CommonTextsService extends TextsService {
    constructor(private config: PrimeNGConfig, ) {
        super();
    }

    setTextsByLanguage(translations: { [key: string]: string }) {
            this.texts = [
                { key: "err_text", value: translations["general.err_text"] },
                { key: "error", value: translations["general.error"] },
                { key: "textok", value: translations["general.textok"] },
                { key: "chat", value: translations["chat.chat"] },
                { key: "app_title", value: translations["general.app_title"] },
                { key: "choose", value: translations["general.choose"] },
                { key: "yes", value: translations["general.textyes"] },
                { key: "no", value: translations["general.textno"] },
                { key: "cancel", value: translations["general.textcancel"] },
                { key: "upload", value: translations["general.upload"] },
                { key: "clear", value: translations["general.clear"] },
                { key: "today", value: translations["general.today"] },
                { key: "no_results_found", value: translations["general.no_results_found"] },
                { key: "paginator_cur_page_msg", value: translations["general.paginator_cur_page_msg"] },
            ];

            this.config.setTranslation({
                choose: translations["general.choose"],
                accept: translations["general.textyes"],
                reject: translations["general.textno"],
                cancel: translations["general.textcancel"],
                upload: translations["general.upload"],
                clear: translations["general.clear"],
                today: translations["general.today"],
                emptyFilterMessage: translations["general.no_results_found"],
            });
    }
}
