import { Location } from "@angular/common";
import { ActivatedRouteSnapshot, DefaultUrlSerializer, PRIMARY_OUTLET, RouterState, UrlTree } from "@angular/router";

export class RoutingHelpers {

    constructor() { }

    static isRouterUrl(url: string): boolean {
        return (new RoutingHelpers()).isRouterUrl(url);
    }

    static urlToRouterLink(url: string): string[] {
        return (new RoutingHelpers()).urlToRouterLink(url);
    }

    static getAllParamsFormRouterState(routerState: RouterState): object {
        let params = {};
        let stack: ActivatedRouteSnapshot[] = [routerState.snapshot.root];
        while (stack.length > 0) {
            const route = stack.pop()!;
            params = { ...params, ...route.params };
            stack.push(...route.children);
        }
        return params;
    }

    isRouterUrl(url: string): boolean {
        // console.log(url);
        let baseUrl = this.getCurrentModuleBaseUrl();
        // console.log(baseUrl);
        let re = new RegExp("^/" + baseUrl + "/", "g");
        // console.log(re.exec(url) !== null);
        if (re.exec(url) !== null) {
            return true;
        }
        return false;
    }

    urlToRouterLink(url: string): any {
        let urlTree = this.urlToUrlTree(url);
        let urlPath = this.getUrlPathFromUrlTree(urlTree);
        // console.log(urlPath)        
        let params = this.getParamsFromUrlTree(urlTree);
        // console.log(params)
        return params ? [urlPath, params] : [urlPath];
    }

    private urlToUrlTree(url: string): UrlTree {
        let serializer = new DefaultUrlSerializer();
        return serializer.parse(url) as UrlTree;
    }
   
    private getUrlPathFromUrlTree(urlTree: UrlTree): string {
        let baseUrl = this.getCurrentModuleBaseUrl();
        let urlPath = '';
        for (let urlSegement of urlTree.root.children[PRIMARY_OUTLET].segments) {
            if (urlSegement.path != baseUrl) {
                urlPath += '/' + urlSegement.path;
            }
        }
        return urlPath;
    }

    private getCurrentModuleBaseUrl() { //ex. /fe, /events, etc.
        // console.log(window.location.pathname.split("/")[1]);
        return window.location.pathname.split("/")[1];
    }

    private getParamsFromUrlTree(urlTree: UrlTree): object {
        let segments = urlTree.root.children[PRIMARY_OUTLET].segments;
        if (!segments?.length) {
            return {};
        }

        let lastSegment = segments[segments.length-1]; 
        return lastSegment.parameterMap.keys.length ? lastSegment.parameters : null;
    }
}