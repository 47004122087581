import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Text } from "@app/common/services/texts/text";
import { TextsService } from "@app/common/services/texts/texts.service";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// interface ResponseIndex {
//     // texts: Text[];
//     nickname: string;
// }

interface ResponseReloadCaptcha {
    url: string;
}

interface ResponseLoginStep1 {
    status: number;
    showCaptcha: boolean;
    errorMsg: string;
    dataStep2: LoginStep2;
    token: Token;
}

export class Token {
    expires_in: number;
    access_token: string;
    token_type: string;
    scope: any;
    refresh_token: string;
}

interface LoginStep2 {
    // texts: Text[];
    loginOpts: SelectItem[];
    replacementOpts: SelectItem[];
}

interface ResponseForgotPass {
    status: number;
    msg: string;
}

interface ResponseLanguages {
    locale: string;
    languages: any;
}

export enum LoginAs {
    LOGIN_BUYER = 1,
    LOGIN_BACKOFFICE = 2,
    LOGIN_VACATION_BUYER = 3,
    LOGIN_VACATION_SUPPLIER = 4,
    LOGIN_SUPPLIER = 5,
    LOGIN_BACKOFFICE_SUPPLIER = 6,
}

export enum LOGIN_TYPES {
    LOGIN_PIN_TOKEN = 1,
    LOGIN_PASSWORD = 2,
    LOGIN_TOTP = 3,
}

@Injectable({
    providedIn: "root",
})
export class HomepagesService {
    private url = "/fw/homepages";
    textsService: TextsService;

    constructor(private http: HttpClient) {
        this.textsService = new TextsService();
    }

    // index(locale: string = null): Observable<ResponseIndex> {
    //     let url = this.url;
    //     // if (locale) {
    //     //     url += "?locale=" + locale;
    //     // }
    //     return this.http.get<ResponseIndex>(url);
    //     // .pipe(
    //     //     map((response) => {
    //     //         if (response.texts) {
    //     //             response.texts = this.textsService.extractTexts(response.texts);
    //     //         }
    //     //         return response;
    //     //     })
    //     // );
    // }

    index(): Observable<any> {
        return this.http.get<any>(this.url);
    }

    reloadCaptcha(): Observable<ResponseReloadCaptcha> {
        return this.http.get<ResponseReloadCaptcha>(`${this.captchaUrl}?refresh=1`);
    }

    get captchaUrl() {
        return `${this.url}/captcha`;
    }

    login(formData): Observable<ResponseLoginStep1> {
        return this.http.post<ResponseLoginStep1>(`${this.url}/login`, formData);
    }

    loginStep2(formData: any, targetUrl: string): Observable<string> {
        let params = formData;
        params.targetUrl = targetUrl;
        return this.http.post<string>(`${this.url}/login-step2`, params);
    }

    resetPassword(formData): Observable<ResponseForgotPass> {
        return this.http.post<ResponseForgotPass>(`${this.url}/send-reset-code`, formData);
    }

    getLanguages(): Observable<ResponseLanguages> {
        let url = `${this.url}/get-languages`;
        return this.http.get<ResponseLanguages>(url);
    }

    loginWith(integrationName: string): Observable<void> {
        return this.http.get<void>(`/users/oauth/${integrationName}/login`);
    }
}
