import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login.component";
import { InputTextModule } from "primeng/inputtext";
import { TemplateValidatorsModule } from "@app/common/custom-widgets/template-validators/template-validators.module";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NativeElementInjectorModule } from "@app/common/directives/native-element-injector/native-element-injector.module";
import { MessagesModule } from "primeng/messages";
import { Step2Component } from "./step2/step2.component";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { RadioButtonModule } from "primeng/radiobutton";
import { DropdownModule } from "primeng/dropdown";
import { ResetPassComponent } from "./reset-pass/reset-pass.component";
import { CheckboxModule } from "primeng/checkbox";
import { LightboxModule } from "primeng/lightbox";
import { RouterModule } from "@angular/router";
import { TranslationModule } from "@app/common/i18n/translation/translation.module";
import { LanguageSwitcherModule } from "../widgets/language-switcher/language-switcher.module";

@NgModule({
    declarations: [LoginComponent, Step2Component, ResetPassComponent],
    imports: [
        CommonModule,
        TranslationModule.forChild(),
        // TranslationModule.forChild({
        //     defaultLanguage: "en",
        //     modulePath: "login",
        // }),
        InputTextModule,
        TemplateValidatorsModule,
        ButtonModule,
        DialogModule,
        ReactiveFormsModule,
        FormsModule,
        NativeElementInjectorModule,
        MessagesModule,
        DynamicDialogModule,
        RadioButtonModule,
        // ScrollPanelModule,
        DropdownModule,
        CheckboxModule,
        LightboxModule,
        RouterModule,
        LanguageSwitcherModule,
    ],
    exports: [LoginComponent, ResetPassComponent],
    providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],
})
export class LoginModule {
    public static components = {
        dynamicComponent: LoginComponent,
    };
}
