import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class LoginGuard implements CanActivate {
    constructor(private cookieService: CookieService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let loginAs = this.cookieService.get("loginAs");
        if (loginAs) {
            window.location.href = "/fw/homepage";
            return false;
        }
        return true;
    }
}
