import { HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSettingsService } from '@app/common/services/user-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, forkJoin, map, of, take, tap } from 'rxjs';
import { TranslationFileProvider } from './translation-file-provider';
import { createTranslateLoader } from './translation.module';
import { TranslationFileProviderConfig } from './translation.classes';
import { AppTranslateHttpLoader } from './app-translate-http-loader';
import { Helpers } from '@app/common/helpers/general.helpers';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    constructor(
        private translateService: TranslateService,
        private httpBackend: HttpBackend,
        private cookieService: CookieService,
        private userSettingsService: UserSettingsService
    ) { }

    public setCurrentLanguage(lang: string): Observable<any> {
        return this.translateService.use(lang);
    }

    /**
    *  Load translations (ex. use when translation is needed on ngOnInit)
    */
    public loadTranslations(): Observable<void> {
        // translateService.get() is the only public method of @ngx-translate service which also checks for loading in progress - to avoid loading same file twice
        let obsCurrentLang = this.translateService.get("Translation file is loaded!");

        if (this.translateService.translations?.[this.translateService.defaultLang]) { //if default translations already loaded
            // console.log("default translations already loaded!");
            return obsCurrentLang;
        }

        let obsDefaultLang = this.translateService.onDefaultLangChange.pipe(take(1));

        return forkJoin({
            obsCurrentLang,
            obsDefaultLang,
        }).pipe(map(() => { return; }));
    }

    public get(key: string | Array<string>, interpolateParams?: Object): string | any {
        return this.translateService.instant(key, interpolateParams);
    }

     /**
     *  Returns all the loaded translations (use with loadTranslations() if not sure that translations are already loaded)
     */
    public getTranslations(): { [key: string]: string } {
        if (!this.translateService.translations?.[this.translateService.defaultLang] || !this.translateService.translations?.[this.translateService.currentLang]) {
            console.log("TranslationService: translations not loaded!");
        }
        return { ...this.translateService.translations[this.translateService.defaultLang], ...this.translateService.translations[this.translateService.currentLang] };
    }

    public setDefaultLanguage(lang: string): void {
        this.translateService.setDefaultLang(lang);
    }

    /**
    *  Load translations directly from file and merge with the existing translations. 
    *  Also file is added to the service's file loader (to load with the new locale on language change)
    * @param {TranslationFileProviderConfig} config Translation file related config needed by the loader 
    */
    public loadTranslationFile(config: TranslationFileProviderConfig): Observable<any> {
        if (!this.translateService.currentLang || !this.translateService.defaultLang) {
            console.log("TranslationService: default and / or current language not set!");
            return;
        }

        let translationFileProvider = new TranslationFileProvider(this.cookieService, config);
        let tempTranslateLoader = createTranslateLoader(this.httpBackend, [translationFileProvider]);

        if (this.translateService.currentLoader instanceof AppTranslateHttpLoader) {
            this.translateService.currentLoader.addTranslationFileProvider(translationFileProvider);
        } else {
            this.translateService.currentLoader = tempTranslateLoader;
        }

        return this.getTranslationsUsingLoader(tempTranslateLoader).pipe(
            map(translations => {
                this.addTranslations(this.translateService.defaultLang, translations.defaultLangTranslations);
                this.addTranslations(this.translateService.currentLang, translations.currentLangTranslations);
                return { ...translations.defaultLangTranslations, ...translations.currentLangTranslations };
            })
        );
    }

    private getTranslationsUsingLoader(translateLoader): Observable<any> {
        let defaultLangTranslations = translateLoader.getTranslation(this.translateService.defaultLang);
        let currentLangTranslations = this.translateService.currentLang != this.translateService.defaultLang ? translateLoader.getTranslation(this.translateService.currentLang) : of({});

        return forkJoin({
            defaultLangTranslations,
            currentLangTranslations,
        });
    }

    private addTranslations(lang: string, translations: any): void {
        this.translateService.setTranslation(lang, translations, true);
    }
}
