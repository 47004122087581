<table width="350" cellpadding="2" cellspacing="0" border="0" align="center">
  <tr class="thcss">
    <td width="350" align="center">
      {{texts.t('contact')}}
    </td>
  </tr>
  <tr class="tdcss">
    <td align="center">
      <div [innerHTML]="texts.t('contact_txt')"></div>
    </td>
  </tr>
</table>