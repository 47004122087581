import { Component, OnInit } from "@angular/core";
import { LogoutService } from "./logout.service";

@Component({
    selector: "app-logout",
    templateUrl: "./logout.component.html",
    styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
    constructor(private logoutService: LogoutService) {}

    ngOnInit(): void {
        this.logoutService.logout().subscribe((response) => {
            if (response) {
                sessionStorage.removeItem("orderId");
                window.localStorage.setItem("logout-event", new Date().toISOString());
                window.location.href = "/fw/homepage";
            }
        });
    }
}
