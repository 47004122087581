<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="resetPassword(formGroup.value)">
    <div class="container-fluid">
        <div class="row">
            <div class="col tit">
                {{"general.reset_login_info" | translate}}
            </div>
            <div class="col text-end">
                <appLanguageSwitcher></appLanguageSwitcher>
            </div>
        </div>

        <div class="row mt-2">
            <div *ngIf="firstPassword" class="col-md-6">
                <div class="flex-grow-1 pb-2 cntTerms" innerHTML="{{'login.terms_cond' | translate}}"></div>
                <div class="mt-1">
                    <ql-validate [formContr]="formGroup.controls.terms">
                        <p-checkbox [formControl]="formGroup.controls['terms']" [binary]="true" label="{{'login.accept_terms' | translate:termsParams}}"></p-checkbox>
                    </ql-validate>
                </div>
            </div>
            <div class="col-md-6">
                <div *ngIf="firstPassword" class="mb-2">
                    {{"login.first_password" | translate}}
                </div>

                <div class="mt-2" style="width: 400px;">
                    <ng-container *ngIf="loginMethod==loginTypes.LOGIN_PASSWORD" [formGroup]="formGroup.controls.pass">
                        <div class="row">
                            <div class="col-md-4">
                                {{"login.password" | translate}}
                            </div>
                            <div class="col-md-8">
                                <ql-validate [formContr]="formGroup.controls.pass.controls.pass">
                                    <input type="password" pPassword formControlName="pass" autocomplete="off" [feedback]="false" class="userField" />
                                </ql-validate>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4">
                                {{"buyer_user.repeat_password" | translate}}
                            </div>
                            <div class="col-md-8">
                                <ql-validate [formContr]="formGroup.controls.pass.controls.pass_repeat">
                                    <input type="password" pPassword formControlName="pass_repeat" autocomplete="off" [feedback]="false" class="userField" />
                                </ql-validate>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="loginMethod==loginTypes.LOGIN_PIN_TOKEN" [formGroup]="formGroup.controls.pin">
                        <div class="row">
                            <div class="col-md-4">
                                {{"buyer_user.pin" | translate}}
                            </div>
                            <div class="col-md-8">
                                <ql-validate [formContr]="formGroup.controls.pin.controls.pin">
                                    <input type="password" pPassword formControlName="pin" autocomplete="off" [feedback]="false" class="userField" />
                                </ql-validate>
                            </div>
                        </div>
                        <div class="row mt-2 mb-2">
                            <div class="col-md-4">
                                {{"buyer_user.pin_repeat" | translate}}
                            </div>
                            <div class="col-md-8">
                                <ql-validate [formContr]="formGroup.controls.pin.controls.pin_repeat">
                                    <input type="password" pPassword formControlName="pin_repeat" autocomplete="off" [feedback]="false" class="userField" />
                                </ql-validate>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="loginMethod==loginTypes.LOGIN_PIN_TOKEN || loginMethod==loginTypes.LOGIN_TOTP">
                        <div class="row">
                            <div class="col-md-4">
                                {{"buyer_user.token_key" | translate}}
                            </div>
                            <div class="col-md-8">
                                <ql-validate [formContr]="formGroup.controls.token_key">
                                    <input type="text" pInputText formControlName="token_key" class="userField" class="userField" />
                                    <i
                                        *ngIf="loginMethod == loginTypes.LOGIN_TOTP"
                                        class="mdi mdi-shuffle-variant"
                                        style="cursor: pointer;"
                                        (click)="regenerateToken()"
                                    ></i>
                                </ql-validate>
                                <div *ngIf="loginMethod == loginTypes.LOGIN_TOTP">
                                    <p-lightbox
                                        *ngIf="formGroup.controls.token_key.value"
                                        [images]="[{source: qrUrl, thumbnail: qrUrl}]"
                                        [autoZIndex]="false"
                                        styleClass="imgQr"
                                    ></p-lightbox>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row mt-2">
                        <div class="col">
                            <button pButton type="submit" icon="mdi mdi-content-save-outline" label="{{'general.textsave' | translate}}"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
