<p-toast position="top-center" [style]="{marginTop: '140px'}"></p-toast>

<p-confirmDialog></p-confirmDialog>
<p-confirmDialog [key]="'nonClosable'" [closable]="false" [styleClass]="'nonClosableConfDlg'"></p-confirmDialog>

<div *ngIf="errorMsg" class="container mt-5 alert alert-danger">{{errorMsg | translate}}</div>

<div id="tbl_main" [class.dialog]="!haveHeaderFooter" class="d-flex flex-column h-100 pb-1">
    <div *ngIf="loader" class="cntSpinner h-100" [ngClass]="{'cntSpinnerTransparent': loaderTransparent}">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <app-header *ngIf="haveHeaderFooter" #header (changeLanguageEvent)="changeLanguage($event)" (chooseHistoryEvent)="chooseHistory($event)"></app-header>

    <div id="bigbody" [class.dialog]="!haveHeaderFooter" class="flex-grow-1 pt-1">
        <a name="top" style="display: none;"></a>

        <div *ngIf="userSettingsService.isSetLocale" class="container-fluid" [style]="{height: 'calc(100% - 1rem)'}">
            <ng-content></ng-content>
            <!--
            <app-chat *ngIf="haveHeaderFooter && chat" [texts]="commonTextsService" [idEvent]="idEvent"></app-chat>
            -->
        </div>
    </div>

    <app-footer *ngIf="haveHeaderFooter" #footer></app-footer>
</div>
