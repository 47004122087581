import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

interface loaderStatus {
    enabled: boolean;
    transparent: boolean;
}

@Injectable({
    providedIn: "root",
})
export class LoaderService {
    private loaderSource = new Subject<loaderStatus>();
    private enabled: boolean;

    loaderChanged$ = this.loaderSource.asObservable();

    constructor() {}

    initLoader(transparent: boolean = false) {
        this.enabled = true;
        this.loaderSource.next({ enabled: true, transparent: transparent });
    }

    stopLoader() {
        this.enabled = false;
        this.loaderSource.next({ enabled: false, transparent: false });
    }

    get isEnabled(): boolean {
        return this.enabled;
    }
}
