<div class="containerLogin" [style]="{color: color}">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
        <div class="inputLogin">
            <div class="d-flex pt-2">
                <div>
                    <div class="p-1" style="line-height: 18px;">
                        <label for="username" class="pe-1">{{"login.username" | translate}}</label>
                    </div>
                    <div class="p-1 mt-1" style="line-height: 18px;">
                        <label for="password" class="pe-1">{{"login.password" | translate}}</label>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="me-1">
                        <ql-validate [formContr]="loginForm.controls.username" styleClass="d-inline-block">
                            <input class="in" formControlName="username" name="username" id="username"
                                autocomplete="username" type="text" pInputText appNativeElementInjector />
                        </ql-validate>
                    </div>
                    <div class="mt-1 me-1">
                        <ql-validate [formContr]="loginForm.controls.password" styleClass="d-inline-block">
                            <input class="in" formControlName="password" name="password" id="password"
                                autocomplete="current-password" type="password" pInputText appNativeElementInjector />
                        </ql-validate>
                    </div>
                </div>
            </div>
        </div>
        <div class="submitLogin">
            <div *ngIf="captchaSrc" class="mt-2 clearfix">
                {{"login.sec_code" | translate}}
                <div class="captchaWrapper card">
                    <img class="card-img-top" src="{{captchaSrc}}" (click)="reloadCaptchaImg()" />
                    <div class="card-body">
                        <i class="reload-btn mdi mdi-reload" title="{{'login.sec_code_reload' | translate}}"
                            (click)="reloadCaptchaImg()"></i>
                        <ql-validate [formContr]="loginForm.controls.captchaCode">
                            <input class="w-100" formControlName="captchaCode" name="captcha_code" type="text"
                                pInputText appNativeElementInjector />
                        </ql-validate>
                    </div>
                </div>
            </div>
            <div class="loginButtons">
                <button class="mt-2 cntButton" pButton type="submit" label="{{'login.txt_login' | translate}}"></button>
                <button *ngIf="loginWith?.microsoft" class="mt-2 ms-2 cntButton" type="button" (click)="doLoginWith('microsoft')" pButton label="{{'login.txt_login_with_microsoft' | translate}}">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="-1 -5 58 58">
                        <path fill="#ff5722" d="M6 6H22V22H6z" transform="rotate(-180 14 14)"></path>
                        <path fill="#4caf50" d="M26 6H42V22H26z" transform="rotate(-180 34 14)"></path>
                        <path fill="#ffc107" d="M26 26H42V42H26z" transform="rotate(-180 34 34)"></path>
                        <path fill="#03a9f4" d="M6 26H22V42H6z" transform="rotate(-180 14 34)"></path>
                    </svg>
                </button>
            </div>
            <ng-container *ngTemplateOutlet="customContentTemplate"></ng-container>
            <div *ngIf="forgotPassw" class="mt-2">
                <a [style]="{color: color}" class="loginLinks" href="#"
                    (click)="showForgotPasswDlg($event)">{{"general.forgot_my_password" | translate}}</a>
            </div>
            <div *ngIf="newSuppReg" class="mt-2">
                <a [style]="{color: color}" class="loginLinks"
                    href="/fe/suppliers/self-registration">{{"negotiation_respondents.full_reg" | translate}}</a>
            </div>
        </div>
    </form>
</div>

<p-dialog header="{{'general.txt_forgot_password' | translate}}" [(visible)]="showForgotPassw">
    <div *ngIf="showForgotPassw" class="forgotPassWrapper">
        <form [formGroup]="forgotPassForm" (ngSubmit)="resetPassword(forgotPassForm.value)">
            <div class="form-group">
                <label class="control-label">{{"general.err_forgot_password" | translate}}</label>
                &nbsp;
                <ql-validate [formContr]="forgotPassForm.controls.email_or_nickname" [position]="'bottom'"
                    [style]="{display:'inline-block'}">
                    <input class="w-100" formControlName="email_or_nickname" name="email" type="text" pInputText
                        appNativeElementInjector />
                </ql-validate>
            </div>
            <div class="pt-2">{{"login.help_reset_pswd" | translate}}</div>
            <div class="pt-2 clearfix">
                <label class="control-label">{{"login.sec_code" | translate}}</label>
                <div class="captchaWrapper card">
                    <img class="card-img-top" src="{{captchaSrcFP}}" (click)="reloadCaptchaImg(true)" />
                    <div class="card-body">
                        <i class="reload-btn mdi mdi-reload" title="{{'sec_code_reload' | translate}}"
                            (click)="reloadCaptchaImg(true)"></i>
                        <!-- <ql-validate [formContr]="forgotPassForm.controls.captcha"> -->
                        <input formControlName="captcha" name="captcha" class="w-100" type="text" pInputText
                            appNativeElementInjector />
                        <!-- </ql-validate> -->
                    </div>
                </div>
            </div>
            <div class="pt-2">
                <button class="mt-2 mb-1" pButton type="submit"
                    label="{{'general.reset_password' | translate}}"></button>
            </div>
        </form>
    </div>
</p-dialog>