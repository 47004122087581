<div *ngIf="!disabled; else contentOnly" [ngStyle]="style" [ngClass]="ngClass">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  <ng-container *ngIf="showOnHover && hasError">
    <i [ngClass]="'errorInfoIcon mdi mdi-information-variant ' + (regularInput ? '' : 'primeNgInput')"
       (mouseenter) ="showErrorMsg=true"
       (mouseleave)="showErrorMsg=false"></i>
  </ng-container>
  <div *ngIf="hasError && showErrorMsg" [ngStyle]="labelStyle" [ngClass]="'validation-msg ' + position">
    <i class="mdi mdi-alert-circle"></i>
    {{validationMsg}}
  </div>
</div>

<ng-template #contentOnly>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>

<!-- because can't be used ng-content twice -->
<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>