import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface Response {
  requestSent: string;
  //requestReceived: string;
  //responseSent: string;
  time: string;
  timezone: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  private url = '/fw/site/sync-clock';

  constructor(private http: HttpClient) { }

  public sync(requestSent: Date) {
    //let strRequestSent = (requestSent.getUTCMonth() + 1 ) + '/' + requestSent.getUTCDate() + '/' + requestSent.getUTCFullYear() + ' ' +
    //                      requestSent.getUTCHours() + ':' + requestSent.getUTCMinutes() + ':' + requestSent.getUTCSeconds();
    //const headers = new HttpHeaders()
      //.set("X-RequestSent", strRequestSent);
    return this.http.get<Response>(this.url/*, {headers}*/);
  }
}
