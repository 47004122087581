import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import localeCs from "@angular/common/locales/cs";
import localeDe from "@angular/common/locales/de";
import localeEnGb from "@angular/common/locales/en-GB";
import localeEsEs from "@angular/common/locales/es";
import localeFa from "@angular/common/locales/fa";
import localeFr from "@angular/common/locales/fr";
import localeHe from "@angular/common/locales/he";
import localeHr from "@angular/common/locales/hr";
import localeHu from "@angular/common/locales/hu";
import localeIt from "@angular/common/locales/it";
import localeNl from "@angular/common/locales/nl";
import localePl from "@angular/common/locales/pl";
import localePtPt from "@angular/common/locales/pt-PT";
import localeRo from "@angular/common/locales/ro";
import localeSk from "@angular/common/locales/sk";
import localeSl from "@angular/common/locales/sl";
import localeSrLatn from "@angular/common/locales/sr-Latn";
import localeZhHans from "@angular/common/locales/zh-Hans";
import localeArEr from '@angular/common/locales/ar-ER';
import localeJa from '@angular/common/locales/ja';
import localeFi from '@angular/common/locales/fi';
import localeTr from '@angular/common/locales/tr';
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ToastModule } from "primeng/toast";
import { LayoutModule } from "./common/layout/layout.module";
import { FrontendBaseComponent } from "./frontend-base.component";
import { XsrfInterceptor } from "./http-interceptors/xsrf-interceptor";
import { TranslationModule } from "./common/i18n/translation/translation.module";

registerLocaleData(localeEnGb, "en-gb");
registerLocaleData(localeRo, "ro");
registerLocaleData(localeHu, "hu");
registerLocaleData(localeDe, "de");
registerLocaleData(localeCs, "cs");
registerLocaleData(localeSk, "sk");
registerLocaleData(localeFi, "fi");
registerLocaleData(localeSk, "ru");
registerLocaleData(localeIt, "it");
registerLocaleData(localeHr, "hr");
registerLocaleData(localeHe, "he-il");
registerLocaleData(localeSrLatn, "sr-Latn-rs");
registerLocaleData(localePtPt, "pt-pt");
registerLocaleData(localeZhHans, "zh-cn");
registerLocaleData(localeSl, "sl");
registerLocaleData(localeFa, "fa-ir");
registerLocaleData(localeFr, "fr-fr");
registerLocaleData(localeNl, "nl");
registerLocaleData(localeEsEs, "es-es");
registerLocaleData(localePl, "pl");
registerLocaleData(localeArEr, "ar-ae");
registerLocaleData(localeJa, "ja-jp");
registerLocaleData(localeTr, "tr");

@NgModule({
    declarations: [FrontendBaseComponent],
    imports: [
        BrowserModule,
        LayoutModule,
        ToastModule,
        ProgressSpinnerModule,
        ConfirmDialogModule,
        TranslationModule.forChild({
            defaultLanguage: "en",
            modulePath: "common", 
            libraryPath: "frontend-base"
        }),
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true }],
    exports: [FrontendBaseComponent],
})
export class FrontendBaseModule { }
