export class DateHelpers {

    public static toLocalISOString(date): string {
        const tzo = -date.getTimezoneOffset();
        const dif = tzo >= 0 ? '+' : '-';
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    }

    public static stripTZ(dateStr: string | Date): string {
        if (!dateStr) {
            return "";
        }

        if (typeof dateStr !== "string" && dateStr instanceof Date) {
            dateStr = dateStr.toString();
        }
        
        return dateStr.replace(/(\+|-)[0-9]{2}:[0-9]{2}$/, "");
    }

    public static parseDate(dateObj: Date): string {
        dateObj.setSeconds(0);
        return dateObj.toString().replace(/\sGMT(\+|-)\d+\s\([\w\W]+\)$/, "");
    }

}