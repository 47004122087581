import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class LogoutService {
    constructor(private httpClient: HttpClient) {}

    logout(): Observable<boolean> {
        let url = "/fw/logout";
        return this.httpClient.get<boolean>(url);
    }
}
