import { PipeTransform, Pipe} from '@angular/core';
//import { DatePipe} from '@angular/common';

import { UserSettingsService } from '../../services/user-settings.service';

@Pipe({
  name: 'pureDate',
  pure: true
})
export class PureDatePipe implements PipeTransform {

  constructor(private userSettingsService: UserSettingsService) {
  }

  transform(value: any, pattern: string = undefined, locale: string = undefined, timezone: string = undefined): string {
    return this.userSettingsService.userDate(value, pattern, locale, timezone);
    //return (new DatePipe(value)).transform(value, pattern, null, locale);
  }
}