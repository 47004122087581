import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { TranslationModule } from "@app/common/i18n/translation/translation.module";
import { FrontendBaseModule } from "@app/frontend-base.module";
import { XsrfInterceptor } from "@app/http-interceptors/xsrf-interceptor";
import { ConfirmationService, MessageService } from "primeng/api";
import { LoginModule } from "../modules/login/login.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, HttpClientModule, AppRoutingModule, LoginModule, FrontendBaseModule],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true }, MessageService, ConfirmationService],
    bootstrap: [AppComponent],
})
export class AppModule {}
