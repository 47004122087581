import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NativeElementInjectorDirective } from './native-element-injector.directive';

@NgModule({
  declarations: [NativeElementInjectorDirective],
  imports: [
    CommonModule
  ],
  exports: [NativeElementInjectorDirective]
})
export class NativeElementInjectorModule { }
