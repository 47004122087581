import { InjectionToken } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { TranslationFileProviderConfig } from "./translation.classes";

export var TRANSLATION_FILE_PROVIDER_CONFIGS = new InjectionToken<TranslationFileProviderConfig[]>("Configuration for translation file provider");

export class TranslationFileProvider {
    constructor(
        private cookieService: CookieService,
        private config: TranslationFileProviderConfig) {
    }

    public getDefaultFilePath(): string {
        return this.getBasePath() + "default/" + this.config.modulePath + "/";
    }

    public hasByScenarioFilePath(): boolean
    {
        return !!this.getDictionaryName();
    }

    public getByScenarioFilePath(): string {
        let dictionaryName = this.getDictionaryName();
        if (!dictionaryName) {
            return null;
        }
        
        return this.getBasePath() + "byScenario/" + dictionaryName + "/" + this.config.modulePath + "/";
    }

    public getPrefix(): string {
        return this.config.prefix || "";
    }

    private getDictionaryName(): string {
        return this.config.dictionaryName || this.cookieService.get("dictionaryName");
    }

    private getBasePath(): string {
        if (!this.config.libraryPath) {
            return "./assets/i18n/";
        }
        return "./assets/" + this.config.libraryPath + "/i18n/";
    }
}