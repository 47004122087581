<div style="width:99%;padding:0;margin:0;" class="clearfix">
  <div name="div_ticker" id="div_ticker" style="float:left;">
    <input name="input_ticker" id="input_ticker" style="width:600px;" class="in_bg" readonly>
  </div>

  <div style="float:right;text-align:right;font-size:12px;">
    {{scenario ? scenario + ' - ' : ''}}
    {{texts.t('copyright')}}
  </div>
</div>
