import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Text } from "@app/common/services/texts/text";
import { Observable } from "rxjs";

interface ResponseIndex {
    texts: Text[];
    loginMethod: number;
    minPassLength: number;
    firstPassword: boolean;
}

interface ResponseResetAuth {
    startPage: string;
    errors: string[];
}

@Injectable({
    providedIn: "root",
})
export class LoginService {
    constructor(private httpClient: HttpClient) {}

    resetPasswordData(code): Observable<ResponseIndex> {
        let url = `/fw/homepages/reset-password-data?resetPwdCode=${encodeURIComponent(code)}`;
        return this.httpClient.get<ResponseIndex>(url);
    }

    saveResetAuth(params): Observable<ResponseResetAuth> {
        let url = "/fw/homepages/save-reset-auth";
        return this.httpClient.post<ResponseResetAuth>(url, params);
    }

    regenerateToken(code): Observable<ResponseResetAuth> {
        let url = "/fw/homepages/regenerate-token";
        return this.httpClient.post<ResponseResetAuth>(url, { code: code });
    }
}
