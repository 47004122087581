import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ClockService } from '../clock.service';
import { UserSettingsService } from '../../services/user-settings.service'
import { timer, Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.css']
})
export class ClockComponent implements OnInit {

  requestSent: Date;
  //requestReceived: Date;
  //responseSent: Date;
  responseReceived: Date;
  //timezone: string;

  time: Date;
  offset: number;

  localDate: Date;
  previousLocalDate: Date;
  
  timerId: Subscription;
  
  @Output() tick = new EventEmitter<Date>();

  constructor(private clockService: ClockService,
              public userSettingsService: UserSettingsService,
              private loaderService: LoaderService) {

  }

  ngOnInit() {
    document.addEventListener('visibilitychange', () => {
      if(document.hidden) {
        //console.debug('stop');
        if(this.timerId) {
          this.timerId.unsubscribe();
        }
        this.localDate = null;
        this.previousLocalDate = null;
      } else {
        //console.debug('start');
        this.sync();
      }
    });


    let sub = this.loaderService.loaderChanged$.subscribe(loader => {
      if(!loader.enabled && !this.time){  //sync time only after loader has been stopped
        this.sync();
        sub.unsubscribe(); //do not sync a seccond time on next loader stopv (page refresh)
      }
    });
  }

  private sync() {
    this.requestSent = new Date();
    this.clockService.sync(this.requestSent)
      .subscribe(response => { this.responseReceived = new Date();
                               // console.debug(this.offset);
                               
                                //console.log('syncTime');
                                this.syncTime(new Date(response.time));
                             });
  }

  private start() {
    // if (typeof(Worker) !== "undefined") {
    //   let w = new Worker("/clock_worker.js");
    //   w.onmessage = (event) => {
    //     //console.debug(event);
    //     this.runClock()
    //   }; 
    // } 

    const timerObs = timer(0, 250);
    this.timerId = timerObs.subscribe(x => {
      this.runClock()
    });
  };

  private runClock() {
    if(typeof this.offset != 'undefined') {

      if(this.localDate) {
        this.previousLocalDate = this.localDate;
      }
      this.localDate = new Date();

      //if(this.previousLocalDate) {
        //console.debug(Math.abs(this.previousLocalDate.getTime() - this.localDate.getTime()));
      //}
      
      if(this.previousLocalDate && (Math.abs(this.previousLocalDate.getTime() - this.localDate.getTime()) > 3000)) { //detect user date change on local machine
        console.debug(Math.abs(this.previousLocalDate.getTime() - this.localDate.getTime()));
        console.debug('date changed on local machine');
        this.sync();
      }

      let time = new Date(this.localDate.getTime() - this.offset);

      if(!this.time || this.time.getSeconds() != time.getSeconds() ) {
        this.time = time;
        this.userSettingsService.setTime(this.time);
        this.tick.emit(this.time);
        //this.userSettingsService.time = this.time;
        //console.debug(this.time);
      }
    }
  }

  private syncTime(serverDate) {
    //console.debug(this.requestSent);
    //console.debug(this.requestReceived);
    //console.debug(this.responseSent);
    //console.debug(this.responseReceived);

    const offsetConnection = (this.responseReceived.getTime() - this.requestSent.getTime()) / 2;
    serverDate = new Date(serverDate.getTime() + offsetConnection);
    
    let localTime = new Date();
    this.offset = localTime.getTime() - serverDate.getTime();
    //console.debug(this.offset);
    this.start();
  };
}
