import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DEFAULT_LANGUAGE, FakeMissingTranslationHandler, MissingTranslationHandler, TranslateCompiler,
    TranslateDefaultParser, TranslateFakeCompiler, TranslateFakeLoader, TranslateLoader, TranslateModule,
    TranslateParser, TranslateService, TranslateStore, USE_DEFAULT_LANG, USE_EXTEND, USE_STORE
} from '@ngx-translate/core';
import { HttpBackend } from '@angular/common/http';
import { UserSettingsService } from '@app/common/services/user-settings.service';
import { TranslationService } from './translation.service';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslationFileProviderConfig, TranslationModuleConfig } from './translation.classes';
import { AppTranslateHttpLoader } from './app-translate-http-loader';
import { CookieService } from 'ngx-cookie-service';
import { TranslationFileProvider, TRANSLATION_FILE_PROVIDER_CONFIGS } from './translation-file-provider';
import { take } from 'rxjs';

export function createTranslationFileProviders(cookieService: CookieService, configs: TranslationFileProviderConfig[]): TranslationFileProvider[] {
    // console.log(configs);
    return configs
        .filter(config => config.modulePath)
        .map(config => new TranslationFileProvider(cookieService, config));
}

export function createTranslateLoader(httpBackend: HttpBackend, translationFileProviders: TranslationFileProvider[]): TranslateLoader {
    // console.log(translationFileProviders.length);
    // console.log(translationFileProviders);
    return new AppTranslateHttpLoader(httpBackend, translationFileProviders, ".json");
}

export function createTranslateService(
    store: TranslateStore,
    currentLoader: TranslateLoader,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingTranslationHandler: MissingTranslationHandler,
    useDefaultLang: boolean,
    isolate: boolean,
    extend: boolean,
    defaultLanguage: string,
    userSettingsService: UserSettingsService
) {
    let translateService = new TranslateService(
        store,
        currentLoader,
        compiler,
        parser,
        missingTranslationHandler,
        useDefaultLang,
        isolate,
        extend,
        defaultLanguage
    );
    if (currentLoader instanceof AppTranslateHttpLoader) {
        userSettingsService.obsOnSetLocale$.pipe(take(1)).subscribe(() => translateService.use(userSettingsService.language));
    }
    return translateService;
}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ]
})
export class TranslationModule {
    // static forRoot(config: TranslationModuleConfig = {}): ModuleWithProviders<TranslateModule> {
    //     if (config.isolate == undefined) {
    //         config.isolate = true;
    //     }
    //     return TranslateModule.forRoot(config);
    // }

    static forChild(config: TranslationModuleConfig = {}): ModuleWithProviders<TranslateModule> {
        // console.log(config);
        return {
            ngModule: TranslateModule,
            providers: this.getProviders(config)
        };
    }

    public static getTranslationFileProviderConfig(config: TranslationFileProviderConfig): Provider {
        return {
            provide: TRANSLATION_FILE_PROVIDER_CONFIGS,
            multi: true,
            useValue: {
                modulePath: config.modulePath,
                dictionaryName: config.dictionaryName,
                libraryPath: config.libraryPath,
                prefix: config.prefix
            }
        };
    }

    private static getProviders(config: TranslationModuleConfig): Provider[] {
        return [
            TranslationModule.getTranslationFileProviderConfig(config),
            TranslateStore,
            {
                provide: TranslationFileProvider,
                useFactory: createTranslationFileProviders,
                deps: [
                    CookieService,
                    TRANSLATION_FILE_PROVIDER_CONFIGS
                ],
            },
            TranslationModule.getTranslateLoaderProvider(),
            config.compiler || { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
            config.parser || { provide: TranslateParser, useClass: TranslateDefaultParser },
            config.missingTranslationHandler || { provide: MissingTranslationHandler, useClass: FakeMissingTranslationHandler },
            { provide: USE_DEFAULT_LANG, useValue: config.useDefaultLang },
            { provide: USE_STORE, useValue: true },
            { provide: USE_EXTEND, useValue: config.extend },
            { provide: DEFAULT_LANGUAGE, useValue: config.defaultLanguage },
            {
                provide: TranslateService,
                useFactory: createTranslateService,
                deps: [
                    TranslateStore,
                    TranslateLoader,
                    TranslateCompiler,
                    TranslateParser,
                    MissingTranslationHandler,
                    USE_DEFAULT_LANG,
                    USE_STORE,
                    USE_EXTEND,
                    DEFAULT_LANGUAGE,
                    UserSettingsService
                ],
            },
            {
                provide: TranslationService,
                deps: [
                    TranslateService,
                    HttpBackend,
                    CookieService,
                    UserSettingsService
                ]
            },
            DialogService
        ];
    }

    private static getTranslateLoaderProvider(): Provider {
        return {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [
                HttpBackend,
                TranslationFileProvider,
            ],
        }
    }
}
