export class Breadcrumb {
    private label: string;
    private url: string;

    constructor(label: string, url: string) {
        this.label = label;
        this.url = url;
    }

    public getLabel(): string {
        return this.label;
    }

    public getUrl(): string {
        return this.url;
    }

    public getModuleBaseUrl(): string {
        return this.url.split("/")[1];
    }
}