import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PureDatePipe } from './pure-date.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PureDatePipe],
  exports: [PureDatePipe]
})
export class PureDateModule { }
