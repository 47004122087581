import { Injectable, EventEmitter, Output } from "@angular/core";
import {
    getLocaleDayNames,
    FormStyle,
    TranslationWidth,
    getLocaleFirstDayOfWeek,
    getLocaleMonthNames,
    getLocaleDateFormat,
    FormatWidth,
    getLocaleTimeFormat,
    formatNumber,
    DatePipe,
} from "@angular/common";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { TreeNode } from "primeng/api";
import { Menu } from "../layout/menu/menu";
import { PrimeNGConfig } from "primeng/api";
import { Contract } from "../layout/header/header.classes";
import { DateHelpers } from "../helpers/date.helpers";

export class LocalizedData {
    firstDayOfWeek: number;
    dayNames: Array<string>;
    dayNamesShort: Array<string>;
    dayNamesMin: Array<string>;
    monthNames: Array<string>;
    monthNamesShort: Array<string>;
    today: string;
    clear: string;
}

export class openEventParams {
    name: string;
}

export interface UpdateHeaderParams {
    eventType: UpdateHeaderEventType;
    data: any;
}

export enum UpdateHeaderEventType {
    WS_ERROR = 1,
    OPEN_CONTRACT = 2,
    AJAX_ERROR = 3,
    OPEN_EVENT = 4,
    OPEN_ORDER = 5,
}

@Injectable({
    providedIn: "root",
})
export class UserSettingsService {
    _locale: string = "en";
    timezone: string = "GMT";
    timeoffset: number = 0;
    decimals: number = 2;
    userName: string;
    time: Date;
    ciScheme: any;
    openContract: Contract;

    //for date / time picker internalization
    localizedData: LocalizedData = {
        firstDayOfWeek: 0,
        dayNames: [],
        dayNamesShort: [],
        dayNamesMin: [],
        monthNames: [],
        monthNamesShort: [],
        today: "Today",
        clear: "Clear",
    };
    localizedDateFormat: string;
    localizedTimeFormat: number;
    firstDayOfWeek: number;

    private onSetLocale = new ReplaySubject<boolean>(1);
    public obsOnSetLocale$ = this.onSetLocale.asObservable();
    public isSetLocale = false;

    private onSetHeader = new ReplaySubject<boolean>(1);
    public obsOnSetHeader$ = this.onSetHeader.asObservable();

    private sourceTick = new Subject<Date>();
    obsTime = this.sourceTick.asObservable();

    private sourceCloseEvent = new Subject<Date>();
    obsCloseEvent = this.sourceCloseEvent.asObservable();

    sourceUpdateHeader = new Subject<UpdateHeaderParams>();
    public obsUpdateHeader$ = this.sourceUpdateHeader.asObservable();

    public activeMenu: Menu;
    public obsActiveMenu$: Observable<Menu>;

    constructor(private config: PrimeNGConfig) { }

    setTime(time: Date) {
        this.time = time;
        this.sourceTick.next(time);
    }

    get language(): string {
        return this._locale.split("-")[0];
    }

    get isoTimezone() {
        //this.timezone is in format +0100, modify it to ISO 8601 format +01:00
        return this.timezone.replace(/(\+|-)([0-9]{2})([0-9]{2})/, "$1$2:$3");
    }

    get locale() {
        return this._locale;
    }

    set locale(value) {
        this._locale = value;

        //console.log(this._locale);
        //console.log(getLocaleDateFormat(this._locale, FormatWidth.Short));
        this.localizedDateFormat = this.convertDateFormat(getLocaleDateFormat(this._locale, FormatWidth.Short));
        this.localizedTimeFormat = getLocaleTimeFormat(this._locale, FormatWidth.Short).indexOf("a") > -1 ? 12 : 24;
        (this.firstDayOfWeek = getLocaleFirstDayOfWeek(this._locale)),
            // this.localizedData = {
            //   firstDayOfWeek: getLocaleFirstDayOfWeek(this._locale),
            //   dayNames: getLocaleDayNames(this._locale,FormStyle.Standalone,TranslationWidth.Wide) as string[],
            //   dayNamesShort: getLocaleDayNames(this._locale,FormStyle.Standalone,TranslationWidth.Abbreviated) as string[],
            //   dayNamesMin: getLocaleDayNames(this._locale,FormStyle.Standalone,TranslationWidth.Short) as string[],
            //   monthNames: getLocaleMonthNames(this._locale,FormStyle.Standalone,TranslationWidth.Wide) as string[],
            //   monthNamesShort: getLocaleMonthNames(this._locale,FormStyle.Standalone,TranslationWidth.Abbreviated) as string[],
            //   today: 'Today',
            //   clear: 'Clear'
            // }
            this.config.setTranslation({
                dayNames: getLocaleDayNames(this._locale, FormStyle.Standalone, TranslationWidth.Wide) as string[],
                dayNamesShort: getLocaleDayNames(this._locale, FormStyle.Standalone, TranslationWidth.Abbreviated) as string[],
                dayNamesMin: getLocaleDayNames(this._locale, FormStyle.Standalone, TranslationWidth.Short) as string[],
                monthNames: getLocaleMonthNames(this._locale, FormStyle.Standalone, TranslationWidth.Wide) as string[],
                monthNamesShort: getLocaleMonthNames(this._locale, FormStyle.Standalone, TranslationWidth.Abbreviated) as string[],
            });

        this.isSetLocale = true;
        this.onSetLocale.next(true);
    }

    public headerIsSet() {
        this.onSetHeader.next(true);
    }

    public setActiveMenuObservable(obsActiveMenu: Observable<Menu>) {
        this.obsActiveMenu$ = obsActiveMenu;
        this.obsActiveMenu$.subscribe((activeMenu: Menu) => this.activeMenu = activeMenu);
    }

    //convert format for datepicker (differences between format returned by angular functions and format accepted by primeng date / time picker)
    convertDateFormat(format) {
        format = format.replace("MMM", "mm").replace("MM", "mm").replace("M", "mm");
        if (format.indexOf("yy") == -1) {
            return format.replace("y", "yy");
        }
        if (format.indexOf("dd") == -1) {
            return format.replace("d", "dd");
        }
        return format;
    }

    parseFormValues(obj, formArrayNames = null, nullToEmptyString = false) {
        return this.parseInputValues(obj, null, true, nullToEmptyString, formArrayNames);
    }

    parseInputValues(obj, numericFields = null, booleanToInteger = false, nullToEmptyString = false, formArrayNames = null) {
        let tempObj = { ...obj };
        // console.log(obj);
        for (let prop in obj) {
            // console.log(prop);
            // console.log(obj[prop]);

            //parse also the FormArray values
            if (formArrayNames && formArrayNames.includes(prop) && obj[prop].length > 0) {
                let tempFormArray = [];
                for (let objFormGroup of obj[prop]) {
                    tempFormArray.push(this.parseInputValues(objFormGroup, numericFields, booleanToInteger, nullToEmptyString));
                }
                // console.log(tempFormArray);
                tempObj[prop] = [...tempFormArray];
            }

            //if prop value is a Date object
            if (obj[prop] instanceof Date && typeof obj[prop].getMonth === "function") {
                tempObj[prop] = this.parseDate(obj[prop]);
            }
            //parse numbers
            // if(numericFields && numericFields.indexOf(prop) !== -1){
            //   let parsedNr = this.parseUserNumber(obj[prop]);
            //   // console.debug(parsedNr);
            //   if(!isNaN(parsedNr) && parsedNr !== null){
            //     tempObj[prop] = parsedNr;
            //   }
            // }

            //transform boolean values to 0 and 1 integers
            if (booleanToInteger && typeof obj[prop] === "boolean") {
                tempObj[prop] = obj[prop] ? 1 : 0;
            }

            //transform null values to empty string
            if (nullToEmptyString && obj[prop] === null) {
                tempObj[prop] = "";
            }
        }

        return tempObj;
    }

    parseDropdownValues(obj) {
        for (let prop in obj) {
            if (obj[prop] && typeof obj[prop].id !== "undefined") {
                obj[prop] = obj[prop].id;
            }
        }
    }

    parseFormData(obj, numericFields = null) {
        let tempObj = this.parseInputValues(obj, numericFields);
        this.parseDropdownValues(tempObj);
        return tempObj;
    }

    parseDate(dateObj: Date): string {
        dateObj.setSeconds(0);
        return dateObj.toString().replace(/\sGMT(\+|-)\d+\s\([\w\W]+\)$/, "");
    }

    userNumber(val, decimals: number = this.decimals, showOnlyIfDecimal = false) {
        // console.log(val);
        if (val !== undefined && val !== null && val !== "") {
            let nrFormat = "1." + (showOnlyIfDecimal ? "0" : String(decimals)) + "-" + String(decimals);
            //console.debug(val);
            let userNumber = formatNumber(val, this.locale, nrFormat);

            /*
      // console.log(userNumber);
      // console.log(this.locale);
      // let nrTest = new Intl.NumberFormat('hu').format(1000);
      let nrTest = new Intl.NumberFormat(this.locale).format(1000);
      // console.log(nrTest);
      if(nrTest.length == 4) {
        // console.log('HERERER');
        if(this.locale == 'es-es'){
          userNumber = userNumber.replace(/\./g, '');
        }
        userNumber = userNumber.replace(/\s/g, ''); //bug on validateNumber angular-l10n on numbers with grouping space on chrome
      }
      */

            return userNumber;
        }
        return "";
    }

    // string Y-m-d H:i:s  eg. 01/11/2020 12:30:00
    // string Y-m-dTH:i:sZ  eg. 01/11/2020T12:30:00+02:00  //timezone icluded
    // Date object

    // // bug 1021 replaced medium with short (31/10/2022 23:00 instead of 31 Oct 2022, 23:00:00)
    // // eventualy shoud be discussed - affects whole application
    // userDate(val, pattern = 'medium', locale = this._locale, timezone = this.timezone) {
    userDate(val, pattern = "short", locale = this._locale, timezone = this.timezone) {
        //return (new DatePipe(val)).transform(val, pattern, null, locale);
        //if(val !== '') {
        //console.log(timezone);
        //console.debug(val);
        if (typeof val == "undefined" || val === null || val == "") {
            return "";
        }

        if (typeof val.getTime === "function") {
            // val is Date object
            timezone = this.extractTZfromDate(val);
        }

        if (typeof val === "string") {
            //force string to be treated in UTC
            let tz = this.extractTZ(val);
            if (tz) {
                // string Y-m-dTH:i:sZ
                val = new Date(val);
                timezone = tz;
            } else {
                // string Y-m-d H:i:s
                val = this.forceDateStrToGMT(val);
            }
        }

        //console.log(val);
        //console.log((new DatePipe(val)).transform(val, pattern, timezone, locale));
        //if(!isNaN(new Date(val).getTime())) {
        if (typeof val.getTime === "function") {
            // val is Date object
            // console.debug(val);
            // console.debug(timezone);
            // console.debug(formatDate(val, pattern, locale, timezone ));
            return new DatePipe(val).transform(val, pattern, timezone, locale);
        }

        return "";
    }

    extractTZfromDate(val: Date) {
        let offset = new Date().getTimezoneOffset() - val.getTimezoneOffset() + this.timeoffset;
        //console.debug(offset);
        let hours: any = Math.floor(Math.abs(offset) / 60);
        if (hours < 10) {
            hours = "0" + hours;
        }
        let min: any = Math.abs(offset) % 60;
        if (min < 10) {
            min = "0" + min;
        }
        let tz = (offset >= 0 ? "+" : "-") + hours + min;
        return tz;
    }

    extractTZ(val: string): string {
        let re = /(\+|-)[0-9]{2}:[0-9]{2}$/;
        let tzMatch = val.match(re);
        if (tzMatch) {
            let tz = tzMatch[0].replace(":", "");
            return tz;
        }

        re = /Z$/;
        tzMatch = val.match(re);
        if (tzMatch) {
            return "UTC";
        }
    }

    stripTZ(dateStr) {
        if (!dateStr) {
            return "";
        }
        return dateStr.replace(/(\+|-)[0-9]{2}:[0-9]{2}$/, "");
    }

    toUserIsoString(date: Date) {
        let dateStr = DateHelpers.toLocalISOString(date);
        console.log(dateStr);
        return dateStr.replace(/(\+|-)[0-9]{2}:[0-9]{2}$/, this.isoTimezone);
    }

    //ECMA-262
    //Input: Tue Aug 19 1975 23:15:30 GMT+0200 (CEST)
    //Output: Tue Aug 19 1975 23:15:30
    stripTzECMA262(dateStr) {
        let re = /\([\w\W]+\)$/;
        dateStr = dateStr.replace(re, "");
        re = /\sGMT(\+|-)\d+/;
        dateStr = dateStr.replace(re, "");
        return dateStr;
    }

    forceDateStrToGMT(val: string): Date {
        //Y-m-d H:i:s
        let arrVal = val.split(" ");
        val = arrVal.join("T") + "Z"; //UTC
        return new Date(val);
    }

    convertModelToTreeNode(arrModels, params = null): TreeNode[] {
        let treeResponse = [];
        for (let model of arrModels) {
            let treeNode = { data: model, children: [], label: undefined, leaf: undefined };
            if (model.children && model.children.length > 0) {
                treeNode.children = this.convertModelToTreeNode(model.children);
            }
            delete treeNode.data.children;
            if (params && params.labelAttr) {
                treeNode.label = model[params.labelAttr];
            }
            if (params && params.leafAttr) {
                treeNode.leaf = !model[params.leafAttr];
            }
            treeResponse.push(treeNode);
        }
        return treeResponse;
    }

    remainingTime(time: Date, headerTimer: boolean = false): string {
        //console.debug(end_time);

        if (!(time instanceof Date) || !(this.time instanceof Date)) {
            return;
        }

        let ddiff = new Date(time.getTime() - this.time.getTime() + 1000);
        let seconds = Math.floor(ddiff.valueOf() / 1000);

        if (headerTimer && seconds == 0) {
            //refresh page auction is ended
            this.sourceCloseEvent.next(null);
            console.debug("auction end !!!");
            //window.setTimeout("bidding.do_refresh('');",1500);
        }

        if (seconds < 0) {
            seconds = 0;
        }

        let d = 0;
        let h = 0;
        let min = 0;
        let sec = 0;

        if (seconds % 60 != 0) {
            sec = seconds % 60;
            seconds -= sec;
        }
        let minutes = seconds / 60;
        if (minutes % 60 != 0) {
            min = minutes % 60;
            minutes -= min;
        }
        let hours = minutes / 60;
        if (hours % 24 != 0) {
            h = hours % 24;
            hours -= h;
        }
        d = hours / 24;

        let rem_time =
            (d < 10 ? "0" : "") +
            String(d) +
            ":" +
            (h < 10 ? "0" : "") +
            String(h) +
            ":" +
            (min < 10 ? "0" : "") +
            String(min) +
            ":" +
            (sec < 10 ? "0" : "") +
            String(sec);
        return rem_time;
    }

    virtualRowHeight(rowHeight: number) {
        const fontSize = 12; //ql body
        const paddingEm = 0.429; //prime ng theme
        let paddingPx = fontSize * paddingEm;
        const borderPx = 1; //prime ng theme

        //                 ?
        return Math.ceil(rowHeight + 2 * paddingPx + borderPx);
    }

    formatBytes(bytes, decimals) {
        if (bytes == 0) return "0 Bytes";
        var k = 1000,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    deepCopy(oldObj: any) {
        var newObj = oldObj;
        if (oldObj && typeof oldObj === "object") {
            if (oldObj instanceof Date) {
                return new Date(oldObj.getTime());
            }
            newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
            for (var i in oldObj) {
                newObj[i] = this.deepCopy(oldObj[i]);
            }
        }
        return newObj;
    }

    // validateNumber(digits, min?: number, max?: number) {
    //   //console.debug(this.locale);
    //   //console.debug(l10nValidateNumber(this.l10nValidation, {digits: digits}, min, max, 'en-Gb'));
    //   return l10nValidateNumber(this.l10nValidation, {digits: digits}, min, max, 'en-gb');
    // }

    get isLtr() {
        return this.locale == "fa-ir" || this.locale == "he-il" || this.locale == "ar-ae";
    }
}
