import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

import { ValidationTextsService } from "../../services/texts/validation-texts.service";
import { UserSettingsService } from "../../services/user-settings.service";
import { Position } from "./template-validators.classes";

@Component({
    selector: "ql-validate",
    templateUrl: "./template-validators.component.html",
    styleUrls: ["./template-validators.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class TemplateValidatorsComponent implements OnInit {
    @Input() formContr: UntypedFormControl;
    @Input() minValue: number;
    @Input() maxValue: number;
    @Input() stepValue: number;
    @Input() style: any;
    @Input() styleClass: string;
    @Input() labelStyle: any;
    @Input() position: Position = Position.right;
    @Input() showOnHover: boolean = false;
    @Input() regularInput: boolean = false;

    @Input() disabled: boolean = false;

    showErrorMsg: boolean;

    @Input() extraErrMessages: Object;

    constructor(private validationTextsService: ValidationTextsService, public userSettingsService: UserSettingsService) {}

    ngOnInit() {
        this.showErrorMsg = this.showOnHover ? false : true;
    }

    get errorsMsgs() {
        return {
            min: { params: [{ name: "1", param: "min" }] },
            max: { params: [{ name: "1", param: "max" }] },
            validateStep: {
                params: [
                    {
                        name: "1", //name of param in dictionary (ex. "Next step must be {1}")
                        param: "step",
                    },
                ], //name of param in the validator's return value (ex. return {validateStep: {step: 100}})
            },
            minValue: { msgKey: "min", params: [{ name: "1", value: this.minValue }] },
            maxValue: { msgKey: "max", params: [{ name: "1", value: this.maxValue }] },
            maxlength: { params: [{ name: "1", param: "requiredLength" }] },
            minlength: { params: [{ name: "1", param: "requiredLength" }] },
        };
    }

    get validationMsg() {
        //console.log(this.formContr);
        //console.log(this.formContr.errors);
        let errorsMsgs = this.errorsMsgs;
        // console.log(errorsMsgs)

        let errors = {};
        if (this.formContr.parent && this.formContr.parent.errors) {
            errors = this.formContr.parent.errors;
        } else if (this.formContr.errors) {
            errors = this.formContr.errors;
        }

        //console.log(errors);

        //if(this.formContr.errors) {
        //for(let error in this.formContr.errors) {
        for (let error in errors) {
            // console.log(error);
            let txtParams = {};
            //if is not defined then the dictionary key must have the same name as the error
            let msgKey = errorsMsgs[error] && errorsMsgs[error].msgKey ? errorsMsgs[error].msgKey : error;
            if (errorsMsgs[error] && errorsMsgs[error].params) {
                // console.debug(errorsMsgs[error].params);
                for (let errorsMsgParam of errorsMsgs[error].params) {
                    if (errorsMsgParam.param) {
                        txtParams[errorsMsgParam.name] = this.userSettingsService.userNumber(errors[error][errorsMsgParam.param], undefined, true);
                    } else {
                        txtParams[errorsMsgParam.name] = this.userSettingsService.userNumber(errorsMsgParam.value, undefined, true);
                    }
                }
            }
            // console.log(txtParams);
            // console.debug(msgKey);
            //console.debug(this.validationTextsService.texts);
            let msg = this.validationTextsService.t(msgKey, txtParams);
            if (msg) {
                return msg;
            }

            if(this.extraErrMessages && this.extraErrMessages[error]) {
                return this.extraErrMessages[error];
            }

            //console.log(errors[msgKey]);
            return errors[msgKey];
        }
        //}
    }

    get hasError() {
        const invalidControl = !!(this.formContr.errors && this.formContr.touched);
        //return invalidControl;
        //console.log(invalidControl);
        //console.log(this.formContr.errors);

        const invalidParent = !!(this.formContr && this.formContr.parent && this.formContr.parent.errors && this.formContr.parent.dirty);
        return invalidControl || invalidParent;
    }

    get ngClass(): any {
        let ngClass = { "validation-wrapper": true, "has-errors": this.hasError, "prime-ng-input": !this.regularInput };
        if (this.styleClass) {
            ngClass[this.styleClass] = true;
        }
        return ngClass;
    }
}
