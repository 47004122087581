<div class="cntMenu">
    <div id="menu" style="width: 100%;" *ngIf="activeMenu">
        <div *ngIf="menuScrollState != 'initial'" class="scrollLeft"><i class="mdi mdi-chevron-double-left" (click)="scrollLeft($event);"></i></div>

        <div #firstRow class="firstRow" [@menuScrollState]="menuScrollState">
            <div class="menu_container" *ngFor="let menu of menus">
                <div
                    id="top_{{menu.id_menu}}"
                    [ngStyle]="{'font-weight' : menu.active ? 'bold' : 'normal'}"
                    [ngClass]="{'menu_elem_base': true, 'menu_active': (menu == activeMenu), 'menu_elem': (menu != activeMenu)}"
                    (click)="changeMainMenu(menu.id_menu)"
                >
                    {{menu.name}}
                </div>
            </div>
        </div>

        <div *ngIf="menuScrollState == 'initial' && haveRightScroll" class="scrollRight">
            <i class="mdi mdi-chevron-double-right" (click)="scrollRight($event);"></i>
        </div>

        <div style="height: 5px; font-size: 0px;" id="menu_line_div">&nbsp;</div>

        <div id="submenu_container_wide" class="submenu_container clearfix" style="width: 100%;">
            <div class="float-start clearfix" *ngFor="let menu of activeMenu.childrens">
                <a
                    *ngIf="!isRouterUrl(menu)"
                    [ngClass]="{'menu_elem_base': true,
                                                'submenu_active': (menu == activeSubMenu),
                                                'submenu_elem': (menu != activeSubMenu),
                                                'float-start': true}"
                    href="{{menu.url}}"
                    >{{menu.name}}</a
                >
                <!-- routerLinkActive="submenu_active" not working for child pages -->
                <a
                    *ngIf="isRouterUrl(menu)"
                    [ngClass]="{'menu_elem_base': true,
                                                  'submenu_active': (menu == activeSubMenu),
                                                  'submenu_elem': (menu != activeSubMenu),
                                                  'float-start': true}"
                    [routerLink]="routerUrl(menu)"
                    [routerLinkActiveOptions]="{exact: true}"
                    [state]="{label: menu.name, routerLink: routerUrl(menu), main:true}"
                    >{{menu.name}}</a
                >
            </div>
        </div>
    </div>
</div>
