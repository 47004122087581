import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { DialogModule} from 'primeng/dialog';

import { PureDateModule } from '../pipes/pure-date/pure-date.module';

import { ContactComponent } from './contact/contact.component';
import { MenuComponent } from './menu/menu.component';
import { ClockComponent } from './clock/clock.component';

import { HistoryComponent } from './history/history.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TooltipModule,
    DropdownModule,
    FormsModule,
    BrowserAnimationsModule,
    MenuModule,
    DialogModule,
    PureDateModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    MenuComponent,
    ClockComponent,
    HistoryComponent
  ],
  providers: [

  ],
})
export class LayoutModule { }
