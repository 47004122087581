import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [LanguageSwitcherComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule
  ],
  exports: [LanguageSwitcherComponent]
})
export class LanguageSwitcherModule { }
