<div [@openClose]="menuIsOpen ? 'open' : 'closed'" id="tbl_menu" style="height: 135px;" class="hcss">
    <div class="middleTopBar w-100" style="height: 3px;"></div>

    <!-- LOGO - LEFT SIDE-->
    <div id="left_side" class="float-start">
        <div id="fix_bg_color_left">
            <div id="logo_back"></div>
            <div style="width: 240px; height: 70px;" id="logo_bkg"></div>
        </div>
    </div>

    <div id="middle_part" class="float-start">
        <!-- FIRST COLUMN -->
        <div id="first_col" class="float-start">
            <ng-container *ngIf="!role && !event && !contract">&nbsp;</ng-container>

            <!--  role  -->
            <div *ngIf="role" style="width: 100%; height: 18px;" class="d-flex">
                <div class="hcss_nobg font_header">{{texts.t('role')}}</div>
                <!--  value of role  -->
                <div class="value1 hcss_nobg font_header flex-grow-1 ps-2">{{role}}</div>
            </div>
            <!-- end role-->

            <!--  event  -->
            <div *ngIf="event" style="width: 100%; height: 32px;" class="d-flex">
                <div class="hcss_nobg font_header">{{texts.t('event')}}</div>
                <div class="value1 hcss_nobg font_header flex-grow-1 ps-2">{{event}}</div>
            </div>
            <!-- end event-->

            <!--  contract  -->
            <div *ngIf="contract" style="width: 100%; height: 19px;" class="d-flex">
                <div class="hcss_nobg font_header">{{texts.t('contract')}}</div>
                <div class="value1 hcss_nobg font_header flex-grow-1 ps-2">{{contract.name}}</div>
            </div>
            <!--  end contract  -->

            <!--  requisition  -->
            <div *ngIf="req_order" style="width: 100%; height: 19px;" class="d-flex">
                <div class="hcss_nobg font_header">
                    <ng-container *ngIf="req_order.type == ORDER_REQ_TYPES.TYPE_REQUISITION">{{texts.t('requisiton')}}</ng-container>
                    <ng-container *ngIf="req_order.type == ORDER_REQ_TYPES.TYPE_ORDER">{{texts.t('order')}}</ng-container>
                </div>
                <div class="value1 hcss_nobg font_header flex-grow-1 ps-2">{{req_order.name}}</div>
            </div>
            <!--  end requisition  -->
        </div>
        <!-- End first column -->

        <!-- SECOND COLUMN -->
        <div id="second_col" class="float-start">
            <!--  remaining time  -->
            <div *ngIf="closeTime" style="width: 100%; height: 24px;">
                <div id="stopwatch_container" *ngIf="evStatusColor">
                    <i class="mdi mdi-timer-sand-empty" pTooltip="{{texts.t('remaining_time')}}" style="vertical-align: middle;"></i>
                    <span pTooltip="{{evStatus}}">
                        <span id="ev_stopwatch" style="vertical-align: middle;">
                            {{remainingTime}}
                        </span>
                        <span class="evStatus" [style.background-color]="evStatusColor"></span>
                    </span>
                </div>
            </div>
            <!--  end remaining time  -->

            <!--  time  -->
            <div style="width: 100%; height: 22px;">
                <i class="mdi mdi-clock" pTooltip="{{texts.t('time')}}" style="vertical-align: middle;"></i>
                <app-clock (tick)="onTick($event)"></app-clock>
            </div>
            <!--  end time  -->
        </div>
        <!-- End second column -->

        <!-- THIRD COLUMN -->
        <div id="third_col" class="float-start">
            <!-- back, refresh and contact icons, right side -->
            <!--  contact  -->
            <div style="overflow: hidden; text-align: center; margin-top: 24px;" class="hcss_nobg contact_icon float-end">
                <p-dialog [(visible)]="displayContactDlg">
                    <app-contact *ngIf="displayContactDlg"></app-contact>
                </p-dialog>
                <i class="mdi mdi-phone mdi-2x link" aria-hidden="true" pTooltip="{{texts.t('call_me')}}" (click)="showContactDlg()"></i>
            </div>
            <!-- <ng-container  *ngIf="menu.menus"> -->
            <!--separator-->
            <!-- <div style="overflow:hidden;text-align:center;margin-top: 24px;" class="hcss_nobg contact_icon float-end">
          <i class="mdi mdi-dots-vertical mdi-2x"></i>
        </div> -->
            <!--refresh-->
            <!-- <div style="overflow:hidden;text-align:center;margin-top: 24px;" class="hcss_nobg contact_icon float-end">
          <a (click)="chooseHistoryEvent.emit();" pTooltip="{{texts.t('refresh')}}">
            <i id="refresh_icon" class="mdi mdi-refresh mdi-2x link" aria-hidden="true"></i>
          </a>
          <div class="refresh_run" style="position:absolute;visibility:hidden;height:1px;width:1px;top:0px;left:0px;"></div>
          <div class="refresh_error" style="position:absolute;visibility:hidden;height:1px;width:1px;top:0px;left:0px;"></div>
        </div> -->
            <!--history-->
            <!-- <div style="overflow:hidden;margin-top: 24px; margin-right:6px;" class="hcss_nobg float-end">
          <span class="mdi mdi-arrow-left-bold-circle-outline mdi-2x link"
                pTooltip="{{texts.t('history')}}" (click)="showHistory($event);"></span>
          <p-menu #menuHist [popup]="true" [model]="history"></p-menu>
        </div> -->
            <!-- </ng-container> -->
            <!--websocket error alert box-->
            <div *ngIf="wsError" style="overflow: hidden; margin-top: 24px; margin-right: 6px;" class="hcss_nobg float-end">
                <span class="mdi mdi-alert mdi-2x link text-warning" pTooltip="{{wsError}}"></span>
            </div>
            <!--Ajax request (XHR) error alert box-->
            <div *ngIf="ajaxError" style="overflow: hidden; margin-top: 24px; margin-right: 6px;" class="hcss_nobg float-end">
                <span class="mdi mdi-alert mdi-2x link text-danger" pTooltip="{{ajaxError}}"></span>
            </div>
        </div>
        <!-- End third column -->

        <div style="clear: both;"></div>
    </div>
    <app-history #historyComponent></app-history>

    <!-- RIGHTSIDE mail, contact, refresh... -->
    <div id="right_side_header" class="float-start">
        <div id="fix_bg_color_right">
            <div id="right_side_background">
                <!-- menu toggle -->
                <div *ngIf="menu.menus" id="h_menu_toggle" style="overflow: hidden; margin-top: 27px; margin-right: 6px;" class="hcss_nobg float-end">
                    <i class="mdi mdi-menu" (click)="toggleMenu()"></i>
                </div>
                <!-- content right -->
                <div class="rightSideContainer clearfix">
                    <!--language selection-->
                    <div
                        [class.readonlyLang]="languages?.length == 1"
                        style="padding-top: 25px; margin-right: 10px;"
                        pTooltip="{{texts.t('language') + ' ' + languageName}}"
                        class="float-end"
                    >
                        <p-dropdown
                            *ngIf="language"
                            [options]="languages"
                            [(ngModel)]="language"
                            optionLabel="value"
                            optionValue="key"
                            styleClass="headerLanguage"
                            (onChange)="changeLanguage($event)"
                            [readonly]="languages?.length == 1"
                            appendTo="body"
                        >
                            <ng-template let-item pTemplate="selectedItem">
                                <span class="fi fi-{{selectedLanguage}}"></span>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                <div class="clearfix" style="width: 140px;">
                                    <div class="float-start me-1">
                                        <span class="fi fi-{{item.country}}"></span>
                                    </div>
                                    <div class="float-start">{{item.value}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>

                    <!--user selection-->
                    <div class="center_class float-end userNameWrapper">
                        <div *ngIf="userName" id="h_user_name" class="link_class loginName">
                            <i class="mdi mdi-account-circle" aria-hidden="true"></i>
                            <span pTooltip="{{userName}}">
                                {{userName}}
                                <!-- {{(userName.length > 13) ? (userName | slice:0:13) + ' ...' : (userName)}} -->
                            </span>
                        </div>
                    </div>

                    <!--mail button-->
                    <div *ngIf="messagesTxt" class="float-end messagesIconWrapper">
                        <a href="/modules/messages/messages.php?change_menu=1" style="text-decoration: none;">
                            <i class="mdi mdi-email-outline mail_icon" aria-hidden="true" pTooltip="{{messagesTxt}}">
                                <span *ngIf="newMessages>0" class="roundcircle">{{newMessages}}</span>
                            </i>
                        </a>
                    </div>
                </div>
                <!-- container right side without triangle -->
            </div>
        </div>
    </div>

    <div style="clear: both;"></div>
    <app-menu #menu></app-menu>
</div>
