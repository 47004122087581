import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TextsService } from '../services/texts/texts.service';
import { Text } from '../services/texts/text';
import { map } from 'rxjs/operators';

interface Response {
  texts: Text[];
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private textsService: TextsService;

  private url = '/fw/site/contact';

  constructor(private http: HttpClient) {
    this.textsService = new TextsService();
  }

  public getContact() {
    return this.http.get<Response>(this.url)
             .pipe(
                map(response => { response.texts = this.textsService.extractTexts(response.texts);
                                  return response;
                                })
              );
  }
}
