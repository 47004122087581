import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChildren, QueryList, ChangeDetectionStrategy } from "@angular/core";
import { SelectItem } from "primeng/api";
import { HomepagesService, LoginAs } from "../../homepages.service";
import { TextsService } from "@app/common/services/texts/texts.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DomSanitizer } from "@angular/platform-browser";
//import { Router } from '@angular/router';
//import { RadioButton } from 'primeng/radiobutton';

@Component({
    selector: "appLoginStep2",
    templateUrl: "./step2.component.html",
    styleUrls: ["./step2.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step2Component implements OnInit {
    // texts: TextsService;
    loginOpts: SelectItem[];
    replBuyerOpts: SelectItem[];
    replSuppOpts: SelectItem[];

    loginAs: number;
    // replacement: string;
    replBuyer: string;
    replSupplier: string;

    isSuppLogin: boolean = false;
    LoginAs = LoginAs;
    manuals: any = {};
    targetUrl: string;

    constructor(
        public dlgConfig: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private homepagesService: HomepagesService,
        private domSanitizer: DomSanitizer //private router: Router
    ) {
        // this.texts = new TextsService();
    }

    ngOnInit() {
        // console.log(this.dlgConfig.data);
        // this.texts.texts = this.dlgConfig.data.texts;
        this.loginOpts = this.createLoginOpts();
        //console.log(this.loginOpts);
        this.loginAs = this.loginOpts[0].value;
        if (this.dlgConfig.data.targetUrl) {
            this.targetUrl = this.dlgConfig.data.targetUrl;
        }
        // if(this.dlgConfig.data.replacementOpts.length > 0){
        //   this.replacement = this.dlgConfig.data.replacementOpts[0].value;
        // }

        if (this.dlgConfig.data.replBuyerOpts.length > 0) {
            this.replBuyerOpts = this.dlgConfig.data.replBuyerOpts;
            this.replBuyer = this.dlgConfig.data.replBuyerOpts[0].value;
        }

        if (this.dlgConfig.data.replSuppOpts.length > 0) {
            this.replSuppOpts = this.dlgConfig.data.replSuppOpts;
            this.replSupplier = this.dlgConfig.data.replSuppOpts[0].value;
        }
        if (this.dlgConfig.data.manuals) {
            this.manuals = this.dlgConfig.data.manuals;
            //console.debug(this.manuals);
        }
    }

    get url(): string {
        return window.location.origin;
    }

    login() {
        // console.log(this.loginAs);
        // console.log(this.replacement);
        let replacement: string = null;
        if (this.loginAs == LoginAs.LOGIN_VACATION_BUYER) {
            replacement = this.replBuyer;
        }
        if (this.loginAs == LoginAs.LOGIN_VACATION_SUPPLIER) {
            replacement = this.replSupplier;
        }
        let formData = { ...this.dlgConfig.data.formData, loginAs: this.loginAs, replacement: replacement };
        this.homepagesService.loginStep2(formData, this.targetUrl).subscribe((url) => {
            if (url) {
                (window as any).location.href = url;
            }
        });
    }

    createLoginOpts(): SelectItem[] {
        for (let opt of this.dlgConfig.data.loginOpts) {
            let icon = null;
            switch (opt.value) {
                case LoginAs.LOGIN_BUYER: {
                    icon = this.dlgConfig.data.srcHelp.buyer;
                    break;
                }
                case LoginAs.LOGIN_BACKOFFICE: {
                    icon = this.dlgConfig.data.srcHelp.backoffice;
                    break;
                }
                case LoginAs.LOGIN_SUPPLIER: {
                    this.isSuppLogin = true;
                    icon = this.dlgConfig.data.srcHelp.supplier;
                    break;
                }
            }
            opt.icon = icon;
        }
        return this.dlgConfig.data.loginOpts;
    }

    get showTersCond() {
        return (this.isSuppLogin && !this.dlgConfig.data.hideSupplierTC) || (!this.isSuppLogin && !this.dlgConfig.data.hideBuyerTC);
    }

    downloadManual(file) {
        (window as any).location = "/fw/homepages/manual?file=" + encodeURIComponent(file);
    }

    // get terms() {
    //   return this.domSanitizer.bypassSecurityTrustHtml(this.texts.t('terms_cond'));
    // }
}
