import { Component, OnInit } from "@angular/core";

import { TextsService } from "../../services/texts/texts.service";
import { PageService, FooterResponse } from "../../services/page.service";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
    texts: TextsService;

    scenario: string;

    constructor(private pageService: PageService) {
        this.texts = new TextsService();
    }

    ngOnInit() {
        this.pageService.getPage().subscribe({
            next: (response) => {
                if (response.footer) {
                    this.loadResponse(response.footer);
                }
            },
            error: (error) => {
                console.debug(error.statusText);
            },
        });
    }

    loadResponse(response: FooterResponse) {
        this.scenario = response.scenario;
        this.texts.texts = response.texts;
    }
}
