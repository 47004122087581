import { Injectable } from "@angular/core";
import { CanLoad, Route, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class HomepagesGuard implements CanLoad {
    hostnames: string[] = [
        "borealis.eeebidql.com",

        "bosal.eeebid.com",

        "mol.esourcing.molgroup.info",
        "molgroup.esourcing.molgroup.info",
        "ina.esourcing.molgroup.info",
        "slovnaft.esourcing.molgroup.info",

        "tender.sourcing.hu",
        "tendersourcing.eeebid.hu", //testing only
        "arlejtes.sourcing.hu",
        "arlejtessourcing.eeebid.hu", //testing only
        "generali.eeebid.hu",
        "coop.eeebid.hu",
        "mondi.eeebid.com",
        "tender.gazaram.hu",
        "tendergazaram.eeebid.hu", //testing only
        "bonbonetti.eeebid.hu",
        "matra.eeebid.hu",
        "hkh.eeebid.hu",
        "clarios.eeebid.com", //demo atm
        "cez.eeebid.com", //demo atm

        "pedranogroup.eeebid.hu",
        "pedrano.eeebid.hu",
        "cordia.eeebid.hu",
        "futureal.eeebid.hu",

        "takarekcsoport.eeebid.hu",

        // "incentage.eeebid.com", //to be removed after update (feb 2023) -> becomes incentage.ebidtopay.com
        // "incentage.ebidtopay.com",

        "schwabo.eeebid.hu",
        "tungsram.eeebid.com",

        "greenergy.eeebid.hu",
        "rmi.eeebid.hu",
        "helloparks.eeebid.hu",

        "vasuttechnika.eeebid.hu",
        "mediaworks.eeebid.hu",

        // "smk.eeebid.com",

        // "aesbuyer.ebidtopay.com",
        // "procout.ebidtopay.com",
        // "dovevivo.ebidtopay.com",
        // "demo.ebidtopay.com",
        // "app.ebidtopay.com",
        // "mirion.ebidtopay.com",
        // "nipendo.ebidtopay.com",
        // "iec.ebidtopay.com",
        // "bbvgroup.ebidtopay.com",
        // "gerosa.ebidtopay.com",
        // "mwfep.ebidtopay.com",
        // "cellularline.ebidtopay.com",
        // "kerkhoff.ebidtopay.com",
        // "exordium.ebidtopay.com",
        // "mein-solar.ebidtopay.com",
        // "ams.ebidtopay.com",
        // "ebids.ebidtopay.com",
        // "e-trova.ebidtopay.com",
        // "denora.ebidtopay.com",
        // "domochemicals.ebidtopay.com",
        // "breuninger.ebidtopay.com",
        // "business-bridge.ebidtopay.com",
        // "aig.ebidtopay.com",
        // "mafcarrefour.ebidtopay.com",
        // "douglas.ebidtopay.com",
        // "kennametal.ebidtopay.com",
        // "exyte.ebidtopay.com",
        // "btlnet.ebidtopay.com",
        // "arca.ebidtopay.com",
        // "dreso.ebidtopay.com",
    ];

    getLoginModule() {
        let hostname = this.getHostName();

        if (hostname == "mol.eeebid.com") {
            hostname = "mol.esourcing.molgroup.info";
        }

        if (hostname == "tendersourcing.eeebid.hu") {
            hostname = "tender.sourcing.hu";
        }
        if (hostname == "arlejtessourcing.eeebid.hu") {
            hostname = "arlejtes.sourcing.hu";
        }
        if (hostname == "tendergazaram.eeebid.hu") {
            hostname = "tender.gazaram.hu";
        }

        if (window.location.pathname == "/homepages/login" && this.hostnames.includes(hostname)) {
            let hostnameFolder = hostname.replace(/\./g, "-");
            //console.debug(hostnameFolder);
            return hostnameFolder;
        }

        return "default";
    }

    public getHostName(): string {
        let hostname = window.location.hostname;

        let arrHostname = hostname.split(".");
        if (arrHostname[0] == "dev" || arrHostname[0] == "local") {
            arrHostname = arrHostname.slice(1);
            hostname = arrHostname.join(".");
        }
        return hostname;
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        // console.log(route);
        // console.log(window.location.hostname);
        // console.log(window.location.pathname);

        let module = this.getLoginModule();
        if (module == "default") {
            route.loadChildren = () => import("../modules/homepages/default/login.module").then((m) => m.LoginModule);
        } else {
            route.loadChildren = () => import("../modules/homepages/" + module + "/login.module").then((m) => m.LoginModule);
        }
        return true;
    }
}
