import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { validateForm } from "@app/common/helpers";
import { LoaderService } from "@app/common/services/loader.service";

import { TranslationService } from "@app/common/i18n/translation/translation.service";
import { MessageService } from "primeng/api";
import { HomepagesGuard } from "projects/homepages/src/route-guards/homepages.guard";
import { LOGIN_TYPES } from "../../homepages.service";
import { LoginService } from "../login.service";
import { UserSettingsService } from "@app/common/services/user-settings.service";

@Component({
    selector: "app-reset-pass",
    templateUrl: "./reset-pass.component.html",
    styleUrls: ["./reset-pass.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPassComponent implements OnInit {
    //   texts = new TextsService();
    loginMethod: number;
    formGroup: UntypedFormGroup;
    loginTypes = LOGIN_TYPES;
    code: string;
    firstPassword: boolean;

    constructor(
        private loader: LoaderService,
        private changeDetectorRef: ChangeDetectorRef,
        private defaultService: LoginService,
        private formBuilder: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        private messageService: MessageService,
        private translationService: TranslationService,
        private homepagesGuard: HomepagesGuard,
        private userSettingsService: UserSettingsService
    ) {}

    ngOnInit(): void {
        this.userSettingsService.obsOnSetLocale$.subscribe(() => {
            this.translationService.loadTranslationFile({
                modulePath: "login",
                dictionaryName: this.homepagesGuard.getHostName().split(".").join("-"),
            }).subscribe();
        });

        //this.code = this.activatedRoute.snapshot.paramMap.get('code');
        console.debug(this.activatedRoute.snapshot.queryParams.resetPwdCode);
        this.code = this.activatedRoute.snapshot.queryParams.resetPwdCode;

        let formGroupParams: any = {
            code: this.code,
        };

        this.defaultService.resetPasswordData(this.code).subscribe((response) => {
            //   this.texts.texts   = response.texts;
            this.loginMethod = response.loginMethod;
            this.firstPassword = response.firstPassword;

            if (this.firstPassword) {
                formGroupParams.terms = [false, Validators.requiredTrue];
            }

            if (this.loginMethod == this.loginTypes.LOGIN_PASSWORD) {
                formGroupParams.pass = this.formBuilder.group(
                    {
                        pass: ["", [Validators.required, Validators.minLength(response.minPassLength)]],
                        pass_repeat: "",
                    },
                    { validator: (g: UntypedFormGroup) => this.validateMatchPasswd(g) }
                );
            } else if (this.loginMethod == this.loginTypes.LOGIN_PIN_TOKEN) {
                formGroupParams.pin = this.formBuilder.group(
                    {
                        pin: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern("[0-9]{4}")]],
                        pin_repeat: "",
                    },
                    { validator: (g: UntypedFormGroup) => this.validateMatchPin(g) }
                );
                formGroupParams.token_key = ["", Validators.required];
            } else if (this.loginMethod == this.loginTypes.LOGIN_TOTP) {
                formGroupParams.token_key = ["", Validators.required];
            }
            this.formGroup = this.formBuilder.group(formGroupParams);

            this.loader.stopLoader();
            this.changeDetectorRef.markForCheck();
        });
    }

    validateMatchPasswd(g: UntypedFormGroup) {
        return g.controls.pass.value == g.controls.pass_repeat.value
            ? null
            : {
                  validateMatchPasswd: this.translationService.get("buyer_user.err_password_check"),
              };
    }

    validateMatchPin(g: UntypedFormGroup) {
        return g.controls.pin.value == g.controls.pin_repeat.value
            ? null
            : {
                  validateMatchPasswd: this.translationService.get("buyer_user.err_pin_check"),
              };
    }

    resetPassword(values) {
        //console.debug(values);
        if (validateForm(this.formGroup) && validateForm(this.formGroup)) {
            this.defaultService.saveResetAuth(values).subscribe((response) => {
                if (response.errors) {
                    response.errors.forEach((msg) => {
                        this.messageService.add({ severity: "error", detail: msg });
                    });
                } else {
                    (window as any).location = response.startPage;
                }
            });
        }
    }

    get qrUrl() {
        return `/fw/homepages/qr?code=${this.code}&token_key=${this.formGroup.value["token_key"]}`;
    }

    regenerateToken() {
        this.defaultService.regenerateToken(this.code).subscribe((response) => {
            this.formGroup.patchValue({ token_key: response });
            this.changeDetectorRef.markForCheck();
        });
    }

    get url(): string {
        return window.location.origin;
    }

    get termsParams(): object {
        return { "0": this.url };
    }
}
