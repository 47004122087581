import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RedirectGuard } from "@app/common/route-guards/redirect.guard";
import { ResetPassComponent } from "../modules/homepages/default/reset-pass/reset-pass.component";
import { LoginComponent } from "../modules/login/login.component";
import { LogoutComponent } from "../modules/login/logout/logout.component";
import { LoginGuard } from "../route-guards/login-guard";
import { AppComponent } from "./app.component";

const routes: Routes = [
    { path: "login", component: LoginComponent, canActivate: [LoginGuard] },
    { path: "logout", component: LogoutComponent },
    { path: "reset-pass", component: ResetPassComponent },
    {
        path: "login/tender-sourcing-hu",
        loadChildren: () => import("../modules/homepages/tender-sourcing-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/arlejtes-sourcing-hu",
        loadChildren: () => import("../modules/homepages/arlejtes-sourcing-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/generali-eeebid-hu",
        loadChildren: () => import("../modules/homepages/generali-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/coop-eeebid-hu",
        loadChildren: () => import("../modules/homepages/coop-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/mondi-eeebid-com",
        loadChildren: () => import("../modules/homepages/mondi-eeebid-com/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/tender-gazaram-hu",
        loadChildren: () => import("../modules/homepages/tender-gazaram-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/bonbonetti-eeebid-hu",
        loadChildren: () => import("../modules/homepages/bonbonetti-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/matra-eeebid-hu",
        loadChildren: () => import("../modules/homepages/matra-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/bosal-eeebid-com",
        loadChildren: () => import("../modules/homepages/bosal-eeebid-com/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/borealis-eeebidql-com",
        loadChildren: () => import("../modules/homepages/borealis-eeebidql-com/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/mol-esourcing-molgroup-info",
        loadChildren: () => import("../modules/homepages/mol-esourcing-molgroup-info/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/molgroup-esourcing-molgroup-info",
        loadChildren: () => import("../modules/homepages/molgroup-esourcing-molgroup-info/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/ina-esourcing-molgroup-info",
        loadChildren: () => import("../modules/homepages/ina-esourcing-molgroup-info/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/slovnaft-esourcing-molgroup-info",
        loadChildren: () => import("../modules/homepages/slovnaft-esourcing-molgroup-info/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/hkh-eeebid-hu",
        loadChildren: () => import("../modules/homepages/hkh-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/clarios-eeebid-com",
        loadChildren: () => import("../modules/homepages/clarios-eeebid-com/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/cez-eeebid-com",
        loadChildren: () => import("../modules/homepages/cez-eeebid-com/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/pedranogroup-eeebid-hu",
        loadChildren: () => import("../modules/homepages/pedranogroup-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/pedrano-eeebid-hu",
        loadChildren: () => import("../modules/homepages/pedrano-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/cordia-eeebid-hu",
        loadChildren: () => import("../modules/homepages/cordia-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/futureal-eeebid-hu",
        loadChildren: () => import("../modules/homepages/futureal-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/takarekcsoport-eeebid-hu",
        loadChildren: () => import("../modules/homepages/takarekcsoport-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    //to be removed in near future (after feb 2023) - hostname changed in ch.ebidtopay.com
    // {
    //     path: "login/letsbuyit-eeebid-com",
    //     loadChildren: () => import("../modules/homepages/ch-ebidtopay-com/login.module").then((m) => m.LoginModule),
    // },
    //to be removed in near future (after feb 2023) - hostname changed in incentage.ebidtopay.com
    // {
    //     path: "login/incentage-eeebid-com",
    //     loadChildren: () => import("../modules/homepages/incentage-eeebid-com/login.module").then((m) => m.LoginModule),
    // },
    {
        path: "login/schwabo-eeebid-hu",
        loadChildren: () => import("../modules/homepages/schwabo-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/tungsram-eeebid-com",
        loadChildren: () => import("../modules/homepages/tungsram-eeebid-com/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/greenergy-eeebid-hu",
        loadChildren: () => import("../modules/homepages/greenergy-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/rmi-eeebid-hu",
        loadChildren: () => import("../modules/homepages/rmi-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/helloparks-eeebid-hu",
        loadChildren: () => import("../modules/homepages/helloparks-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/vasuttechnika-eeebid-hu",
        loadChildren: () => import("../modules/homepages/vasuttechnika-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/mediaworks-eeebid-hu",
        loadChildren: () => import("../modules/homepages/mediaworks-eeebid-hu/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/bauer-eeebid-hu",
        loadChildren: () => import("../modules/homepages/__standard/login.module").then((m) => m.LoginModule),
    },

    //-----------------  BEGIN HOMEPAGES EBIDTOPAY --------------------------
    { path: "login/procout-ebidtopay-com", loadChildren: () => import("../modules/homepages/procout-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/aesbuyer-ebidtopay-com", loadChildren: () => import("../modules/homepages/aesbuyer-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/dovevivo-ebidtopay-com", loadChildren: () => import("../modules/homepages/dovevivo-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/demo-ebidtopay-com", loadChildren: () => import("../modules/homepages/demo-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/app-ebidtopay-com", loadChildren: () => import("../modules/homepages/app-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/mirion-ebidtopay-com", loadChildren: () => import("../modules/homepages/mirion-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/nipendo-ebidtopay-com", loadChildren: () => import("../modules/homepages/nipendo-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/iec-ebidtopay-com", loadChildren: () => import("../modules/homepages/iec-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/bbvgroup-ebidtopay-com", loadChildren: () => import("../modules/homepages/bbvgroup-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/gerosa-ebidtopay-com", loadChildren: () => import("../modules/homepages/gerosa-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/mwfep-ebidtopay-com", loadChildren: () => import("../modules/homepages/mwfep-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    {
        path: "login/cellularline-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/cellularline-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    { path: "login/kerkhoff-ebidtopay-com", loadChildren: () => import("../modules/homepages/kerkhoff-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/exordium-ebidtopay-com", loadChildren: () => import("../modules/homepages/exordium-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    {
        path: "login/mein-solar-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/mein-solar-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    { path: "login/ams-ebidtopay-com", loadChildren: () => import("../modules/homepages/ams-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/ebids-ebidtopay-com", loadChildren: () => import("../modules/homepages/ebids-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/e-trova-ebidtopay-com", loadChildren: () => import("../modules/homepages/e-trova-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    {
        path: "login/viviennewestwood-ebidtopay-it",
        loadChildren: () => import("../modules/homepages/viviennewestwood-ebidtopay-it/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/viviennewestwood-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/viviennewestwood-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    { path: "login/denora-ebidtopay-com", loadChildren: () => import("../modules/homepages/denora-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    {
        path: "login/domochemicals-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/domochemicals-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
	//to be removed in near future - redirect in ngix to .it
    // { path: "login/cna-ebidtopay-com", loadChildren: () => import("../modules/homepages/cna-ebidtopay-it/login.module").then((m) => m.LoginModule) },
    { path: "login/cna-ebidtopay-it", loadChildren: () => import("../modules/homepages/cna-ebidtopay-it/login.module").then((m) => m.LoginModule) },
    {
        path: "login/breuninger-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/breuninger-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    {
        path: "login/business-bridge-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/business-bridge-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    { path: "login/aig-ebidtopay-com", loadChildren: () => import("../modules/homepages/aig-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    {
        path: "login/mafcarrefour-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/mafcarrefour-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    { path: "login/douglas-ebidtopay-com", loadChildren: () => import("../modules/homepages/douglas-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    {
        path: "login/kennametal-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/kennametal-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    { path: "login/exyte-ebidtopay-com", loadChildren: () => import("../modules/homepages/exyte-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/btlnet-ebidtopay-com", loadChildren: () => import("../modules/homepages/btlnet-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/arca-ebidtopay-com", loadChildren: () => import("../modules/homepages/arca-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    //------------------------------former letsbuyit.eeebid.com page---------------------------------------
    {
        path: "login/ch-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/ch-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    //------------------------------former incentage.eeebid.com page---------------------------------------
    {
        path: "login/incentage-ebidtopay-com",
        loadChildren: () => import("../modules/homepages/incentage-ebidtopay-com/login.module").then((m) => m.LoginModule),
    },
    { path: "login/dreso-ebidtopay-com", loadChildren: () => import("../modules/homepages/dreso-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/ynap-ebidtopay-com", loadChildren: () => import("../modules/homepages/ynap-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/emd-ebidtopay-com", loadChildren: () => import("../modules/homepages/emd-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/post-ebidtopay-com", loadChildren: () => import("../modules/homepages/post-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    { path: "login/tvarit-ebidtopay-com", loadChildren: () => import("../modules/homepages/tvarit-ebidtopay-com/login.module").then((m) => m.LoginModule) },
    
    { path: "login/doka-ebidtopay-com", loadChildren: () => import("../modules/homepages/__standard/login.module").then((m) => m.LoginModule) },
    //-----------------  END HOMEPAGES EBIDTOPAY --------------------------

    // {
    //     path: "login/local-eeebid-com",
    //     loadChildren: () => import("../modules/homepages/local-eeebid-com/login.module").then((m) => m.LoginModule),
    // },
    {
        path: "login/smk-eeebid-com",
        loadChildren: () => import("../modules/homepages/smk-eeebid-com/login.module").then((m) => m.LoginModule),
    },

    {
        path: "**",
        canActivate: [RedirectGuard],
        component: AppComponent,
        data: {
            externalUrl: "/fw/error",
        },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
