<div class="d-flex flex-column h-100 p-2">
    <div class="pt-1 clearfix">
        <div class="float-start me-2 fw-bolder">
            {{"login.login_as" | translate}}
        </div>
        <div class="float-start">
            <div *ngFor="let opt of dlgConfig.data.loginOpts" style="height: 26px;" class="d-flex align-items-center">
                <p-radioButton name="loginAs" value="{{opt.value}}" label="{{opt.label | translate}}" [(ngModel)]="loginAs"></p-radioButton>
                <p-dropdown
                    *ngIf="opt.value == LoginAs.LOGIN_VACATION_BUYER"
                    class="col-md-2"
                    [options]="replBuyerOpts"
                    [(ngModel)]="replBuyer"
                    class="ps-1"
                ></p-dropdown>
                <p-dropdown
                    *ngIf="opt.value == LoginAs.LOGIN_VACATION_SUPPLIER"
                    class="col-md-2"
                    [options]="replSuppOpts"
                    [(ngModel)]="replSupplier"
                    class="ps-1"
                ></p-dropdown>
                <i
                    *ngIf="manuals[opt.value]"
                    class="mdi mdi-help-circle action ms-2"
                    (click)="downloadManual(manuals[opt.value])"
                    title="{{manuals[opt.value]}}"
                ></i>
            </div>
        </div>
    </div>
    <hr />
    <ng-container *ngIf="showTersCond">
        <div class="pb-2" [translate]="'login.accept_terms'" [translateParams]="{'0': url}"></div>
        <div class="flex-grow-1 pb-2 cntTerms" [innerHTML]="'login.terms_cond' | translate"></div>
    </ng-container>
    <div class="mt-2 mb-1">
        <div class="col text-center">
            <p-button class="me-2" label="{{'login.terms_cond_agree' | translate}}" (click)="login()"></p-button>
            <p-button label="{{'login.terms_cond_disagree' | translate}}" (click)="ref.close()"></p-button>
        </div>
    </div>
</div>
