import { Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { trigger, state, style, animate, transition, query } from "@angular/animations";
import { PlatformLocation } from "@angular/common";

import { Menu } from "./menu";
import { urlPath } from "@app/common/helpers";
import { BreadcrumbsService } from "@app/common/services/breadcrumbs/breadcrumbs.service";
import { Breadcrumb } from "@app/common/services/breadcrumbs/breadcrumb.class";
import { RoutingHelpers } from "@app/common/helpers/routing.helpers";
import { Observable, ReplaySubject, Subject, take } from "rxjs";

@Component({
    selector: "app-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger("menuScrollState", [
            //transition('* => scrollRight', [style({marginLeft: '-100px'}), animate('500ms ease-in')]),
            //transition('* => initial', [style({marginLeft: 0}), animate('500ms ease-out')]),

            state(
                "initial",
                style({
                    marginLeft: "0",
                })
            ),
            state(
                "scrollRight",
                style({
                    marginLeft: "-400px",
                })
            ),
            transition("initial => scrollRight", animate("500ms ease-in")),
            transition("scrollRight => initial", animate("500ms ease-out")),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    @ViewChild("firstRow") firstRow: any;

    menus: Menu[];
    // subMenus: Menu[];

    //activeUrl: string;
    activeMenu: Menu;
    activeSubMenu: Menu;

    private sourceActiveMenu: Subject<Menu> = new ReplaySubject<Menu>(1);
    public obsActiveMenu$: Observable<Menu> = this.sourceActiveMenu.asObservable();

    menuScrollState = "initial";

    constructor(private changeDetectorRef: ChangeDetectorRef, private platformLocation: PlatformLocation, private breadcrumbsService: BreadcrumbsService) {}

    resizeListener() {
        this.changeDetectorRef.markForCheck();
    }

    ngOnInit() {
        (window as any).addEventListener("resize", this.resizeListener.bind(this));
    }

    changeMainMenu(idMenuParent: number) {
        //let selectedMainMenu = this.menus.find( menu => menu.id_menu == idMenuParent );
        //this.subMenus = selectedMainMenu.childrens;
        this.activeMenu = this.menus.find((menu) => menu.id_menu == idMenuParent);
        if (this.activeMenu.childrens.length == 0) {
            //console.debug(this.activeMenu.url);
            (window as any).location = this.activeMenu.url;
        }
        this.changeDetectorRef.markForCheck();
    }

    setMenu(url: string = null, fromStorage = false) {
        if (!url) {
            url = (this.platformLocation as any).location.pathname;
        }
        // if (url.includes(";")) {
        //     //if url includes matrix param(s)
        //     url = urlPath(url);
        // }

        // console.log(this.menus);
        if (this.menus) {
            // console.debug('search: ' + '/fe' + url);
            // console.debug(url);
            //console.debug(this.menus);
            let menuParent = this.menus.find((menu) => {
                let found = false;
                if (menu.url == url || menu.url == "/fw" + url) {
                    sessionStorage.setItem("lastMenuUrl", url);
                    found = true;
                }

                if (menu.childrens.length) {
                    // console.debug(menu.childrens);
                    // console.debug(url);
                    let subMenu = menu.childrens.find((subMenu) => subMenu.url == "/fw" + url || subMenu.url == url);
                    if (subMenu) {
                        this.activeSubMenu = subMenu;
                        sessionStorage.setItem("lastMenuUrl", url);
                        return true;
                    }
                } else {
                    //no sub menu exists
                    if (menu.url == "/fw" + url || menu.url == url) {
                        sessionStorage.setItem("lastMenuUrl", url);
                        return true;
                    }
                }

                if (found) {
                    return true;
                }
            });
            //console.debug(menuParent);
            if (menuParent) {
                this.activeMenu = menuParent;
            } else if (sessionStorage.getItem("lastMenuUrl") && !fromStorage) {
                //console.debug(sessionStorage.getItem('lastMenuUrl'));
                //console.log(sessionStorage.getItem('lastMenuUrl'));
                this.setMenu(sessionStorage.getItem("lastMenuUrl"), true);
            }
            // console.log( url );
            // console.log( menuParent ) ;
            this.sourceActiveMenu.next(this.activeSubMenu ?? this.activeMenu);
            this.changeDetectorRef.markForCheck();
        }
        this.setBreadcrumbs();
    }

    private setBreadcrumbs() {
        // console.log(this.activeMenu);
        // console.log(this.activeSubMenu);
        let breadcrumbs = this.activeMenu ? [new Breadcrumb(this.activeMenu.name, this.activeMenu.url)] : [];
        if (this.activeSubMenu) {
            breadcrumbs = [...breadcrumbs, new Breadcrumb(this.activeSubMenu.name, this.activeSubMenu.url)];
        }

        if (breadcrumbs.length) {
            this.breadcrumbsService.setBreadcrumbs(breadcrumbs);
        }
    }

    isRouterUrl(menu) {
        return RoutingHelpers.isRouterUrl(menu.url);
        // // console.log(isRouterUrl);
        // let baseHref = (this.platformLocation as any).location.pathname.split("/")[1];
        // // console.log(baseHref);
        // //let re = /^\/fe\//;
        // let re = new RegExp("^/" + baseHref + "/", "g");
        // // console.log(re.exec(menu.url));
        // if (re.exec(menu.url) !== null) {
        //     return true;
        // }
        // return false;
    }

    routerUrl(menu) {
        return RoutingHelpers.urlToRouterLink(menu.url);
        // let baseHref = (this.platformLocation as any).location.pathname.split("/")[1];
        // // console.log(baseHref);
        // //let re = /^\/fe\//;
        // let re = new RegExp("^/" + baseHref + "/", "g");
        // return menu.url.replace(re, "");
        // // let url = menu.url.replace(re, '');
        // // return url.split(';')[0];
    }

    // routerUrlParams(menu) {
    //   //console.debug(menu);
    //   let arrParams = menu.url.split(';').slice(1);
    //   if(arrParams.length > 0) {
    //     let params;
    //     arrParams.forEach(item => {
    //       let paramParts = item.split('=');
    //       if(!params) {
    //         params = {};
    //       }
    //       params[paramParts[0]] =  paramParts[1] ? paramParts[1] : null;
    //     });
    //     return params;
    //   }
    // }

    scrollRight(event) {
        this.menuScrollState = "scrollRight";
    }

    scrollLeft(event) {
        this.menuScrollState = "initial";
    }

    get haveRightScroll(): Boolean {
        if (this.firstRow) {
            let firstRowWidth = this.firstRow.nativeElement.clientWidth;
            var totalWidthMenus = 0;

            for (let i = 0; i < this.firstRow.nativeElement.childNodes.length; i++) {
                let menu = this.firstRow.nativeElement.childNodes[i];
                if (menu.clientWidth) {
                    totalWidthMenus += menu.clientWidth;
                }
            }

            return firstRowWidth < totalWidthMenus;
        }

        return false;
    }
}
