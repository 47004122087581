import { Injectable } from "@angular/core";
import { Text } from "./text";
// import { resolveTxt } from 'dns';

@Injectable({
    providedIn: "root",
})
export class TextsService {
    public texts: Text[] = [];

    constructor() {}

    t(key: string, params: any = null) {
        if (this.texts) {
            let match = this.texts.find((text) => text.key == key);
            if (match) {
                let txt = match.value;
                if (params) {
                    txt = this.replaceParams(txt, params);

                    //for(let key in params) {
                    //txt = txt.replace(new RegExp('\{' + key + '\}', 'g'), params[key]);
                    //txt = txt.replace('{' + key + '}', params[key]);
                    //}
                }
                return txt;
            }
        }
    }

    public add(key: string, value: string) {
        this.texts.push({ key: key, value: value });
    }

    private replaceParams(txt: string, params: any) {
        //console.debug(txt);
        let foundReplaced = false;
        for (let key in params) {
            let found = txt.indexOf("{" + key + "}") !== -1;
            if (found) {
                txt = txt.replace("{" + key + "}", params[key]);
                if (!foundReplaced) {
                    foundReplaced = true;
                }
            }
        }

        //recursive until there is nothing to replace
        if (foundReplaced) {
            txt = this.replaceParams(txt, params);
        }

        return txt;
    }

    extractTexts(responseTexts: any): Text[] {
        return Object.keys(responseTexts).map((key) => {
            let text: Text = new Text();
            text.key = key;
            text.value = responseTexts[key];
            return text;
        });
    }

    setTexts(texts: Text[]) {
        this.texts = texts;
    }

    setTextsFromObject(texts: any) {
        this.texts = this.extractTexts(texts);
    }
}
