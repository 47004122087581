import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpResponse, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { throwError } from 'rxjs';
import { UpdateHeaderEventType, UserSettingsService } from '../common/services/user-settings.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {

  constructor(private userSettingsService: UserSettingsService,
              private cookieService: CookieService
             ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler,) {
    // console.log(req.method);

    let newReq = req;
    let headersObj = {};
    if(['GET', 'HEAD','OPTIONS','TRACE'].indexOf(req.method) === -1){
      //const token = sessionStorage.getItem('token');
      const token = this.cookieService.get('X-CSRF-Token');
      // console.debug(token);
      if(token){
        headersObj['X-CSRF-Token'] = token;
      }
    }
      //if(req.method == 'POST'){
    headersObj['X-Requested-With'] = 'XMLHttpRequest';
      //}
      //console.debug(newReq.headers.get('Content-Type'));
    //}
    
    // const authToken = sessionStorage.getItem('authToken');
    // //console.log(authToken);
    // if(authToken){
    //   headersObj['X-Auth-Token'] = authToken;
    // }
    
    //preserve already set headers
    for(let headerKey of req.headers.keys()) {
      headersObj[headerKey] = req.headers.get(headerKey);
    }
    
    let headers = new HttpHeaders(headersObj);
    newReq = req.clone({ headers });

    return next.handle(newReq).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.userSettingsService.sourceUpdateHeader.next({eventType: UpdateHeaderEventType.AJAX_ERROR,
                                                              data: null});
          }
          return event;
        }),
        catchError((event: HttpErrorResponse) => {
          if((event.status == 301 || event.status == 302) && (event.headers.get('X-Redirect'))) {
            console.debug('redirect by X-Redirect');
            window.location.href = event.headers.get('X-Redirect');
            //return throwError(event);
            return;
          }
          
          this.userSettingsService.sourceUpdateHeader.next({eventType: UpdateHeaderEventType.AJAX_ERROR,
                                                            data: event?.statusText || ''});
          return throwError(event);
        }));
  }
}