import { HttpBackend, HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { TranslationFileProvider } from "./translation-file-provider";

export class AppTranslateHttpLoader implements TranslateLoader {

    private httpClient: HttpClient;

    constructor(
        httpBackend: HttpBackend,
        private translationFileProviders: TranslationFileProvider[],
        private suffix?: string
    ) { 
        this.httpClient = new HttpClient(httpBackend);
    }

    addTranslationFileProvider(translationFileProvider: TranslationFileProvider): void {
        this.translationFileProviders.push(translationFileProvider);
    }

    getTranslation(lang: string): Observable<Object> {
        // console.log(lang);
        // console.log(this.translationFileProviders);
        if (!this.translationFileProviders?.length) {
            return of({});
        }

        if (this.translationFileProviders.length == 1) {
            return this.getTranslationByProvider(lang, this.translationFileProviders[0]);
        }

        const requests: Observable<Object | {}>[] = this.translationFileProviders.map(translationFileProvider => {
            return this.getTranslationByProvider(lang, translationFileProvider);
        });
        return forkJoin(requests).pipe(
            // tap(data => console.log(data)),
            map((response) => {
                let mergedTranslations = Object.assign({}, ...response);
                // console.log(mergedTranslations);
                return mergedTranslations;
            }),
            // tap(data => console.log(data))
        );
    }

    getTranslationByProvider(lang: string, translationFileProvider: TranslationFileProvider): Observable<Object> {
        // console.log("getTranslation");
        // console.log(lang);
        // console.log(translationFileProvider);
        // console.log(translationFileProvider.hasByScenarioFromSettings());
        let translations = this.loadTranslation(lang, translationFileProvider.getDefaultFilePath(), translationFileProvider.getPrefix());
        if (!translationFileProvider.hasByScenarioFilePath()) {
            return translations;
        }

        let byScenarioTranslations = this.loadTranslation(lang, translationFileProvider.getByScenarioFilePath(), translationFileProvider.getPrefix());

        return forkJoin({
            translations,
            byScenarioTranslations,
        }).pipe(
            // tap(data => console.log(data)),
            map((response) => {
                let mergedTranslations = new Object({
                    ...response.translations,
                    ...response.byScenarioTranslations,
                });
                return mergedTranslations;
            }),
            // tap(data => console.log(data))
            //   catchError(err => {
            //     console.log(err);
            //   })
        )
    }

    private loadTranslation(lang: string, filePath: string, prefix: string): Observable<Object> {
        return this.httpClient.get(`${filePath}${lang}${this.suffix}`).pipe(
            // tap(data => console.log(data)),
            map((response) => {
                return this.prependPrefix(response, prefix);
            }),
            catchError(err => {
                //console.log(`Locale ${lang} not supported`);
                return of({});
            })
        );
    }

    private prependPrefix(translations: any, prefix: string): any {
        if (!prefix) {
            return translations;
        }

        let prefixedTranslations = {};
        for (let key in translations) {
            prefixedTranslations[prefix + key] = translations[key];
        }

        return prefixedTranslations;
    }
}