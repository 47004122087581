import { UntypedFormGroup, FormArray } from "@angular/forms";
import { DefaultUrlSerializer, PRIMARY_OUTLET, UrlTree } from "@angular/router";
import { MenuItem, Message, SelectItem } from "primeng/api";

//form validation with show errors
export function validateForm(form: UntypedFormGroup | FormArray, focus = false): boolean {
    //console.log(form);
    let invalidInputNode;
    if (form.invalid) {
        Object.keys(form.controls).forEach((field) => {
            const control = form.get(field);
            // console.log(field);
            // console.log(control);

            // if(control instanceof FormArray && control.controls.length>0 && control.controls[0] instanceof FormGroup){
            //   for(let index in control.controls){
            //     validateForm(<FormGroup>control.controls[index]);
            //   }
            // }
            if ((control as UntypedFormGroup).controls) {
                let subFormGroup = control as UntypedFormGroup;
                validateForm(subFormGroup, focus);
            }

            control.markAsTouched({ onlySelf: true });
            // console.log(field);
            // console.log(control.errors);
            //console.debug((control as any));
            //console.debug((control as any).nativeElement);
            //console.debug(control.valid);
            //console.debug(focus);
            let nativeElement = (control as any).nativeElement;
            // console.debug(nativeElement);
            if (nativeElement && !control.valid && focus) {
                //console.debug((control as any).nativeElement.nodeName);
                //make sure appNativeElementInjector (NativeElementInjectorDirective) is added to input
                let input = nativeElement.nodeName == "INPUT" || nativeElement.nodeName == "TEXTAREA" ? nativeElement : nativeElement.querySelector("input");
                //console.debug(input);
                if (input) {
                    //console.debug('focus element');

                    if (!invalidInputNode) {
                        invalidInputNode = input;
                    }
                    //input.focus();
                    //break;
                }
            }
        });

        // console.debug(invalidInputNode);
        if (invalidInputNode) {
            invalidInputNode.focus();
            invalidInputNode.scrollIntoView();
        }

        return false;
    }
    return true;
}

// tinyMCE editor config
export let editorConfig = {
    base_url: "/fe/assets/tinymce",
    suffix: ".min",
    height: 420,
    menubar: false,
    plugins: [
        "advlist autolink lists link image imagetools charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
        "undo redo | formatselect | bold italic backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "bullist numlist outdent indent | removeformat help | " +
        "insertfile image link | " +
        // 'table | code fullscreen',
        "table | code",
    //local png emoticons settings
    emoticons_database: "emojiimages",
    emoticons_images_url: "/fe/assets/smileys/tinymce/",
    relative_urls: false,
    //end local png emoticons settings
    automatic_uploads: true,
    image_description: false,
    // image_caption: true,
    image_title: true,
    file_picker_types: "image",
    file_picker_callback: function (callback) {
        let input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.onchange = function () {
            const file = input.files[0];
            // console.log(file);
            const reader = new FileReader();
            reader.onload = function () {
                let arr = convertDataURIToBinary(reader.result);
                let blob = new Blob([arr], { type: file.type });
                callback(window.URL.createObjectURL(blob), { title: file.name });
                // callback(reader.result, { title: file.name });
            };
            reader.readAsDataURL(file);
        };
        input.click();
    },
    images_upload_handler: function (blobInfo, success, failure) {
        // console.log(blobInfo.base64());
        // console.log(blobInfo.blob());
        success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
    },
};

function convertDataURIToBinary(dataURI) {
    const BASE64_MARKER = ";base64,";
    let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    let base64 = dataURI.substring(base64Index);
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export const imgExts = [".gif", ".jpg", ".jpeg", ".png", ".tiff"];
export const excelExts = [".xlsx", ".xlsm", ".xltx", ".xltm", ".xls", ".xlt", ".xlm"];
export const wordExts = [".docx", ".odt", ".doc"];
export const zipExts = [".zip"];

function fileHasExtOf(filename, arrExts) {
    const ext = filename.split(".").pop();
    return arrExts.includes("." + ext.toLowerCase());
}

export function hasImageExt(filename: string) {
    return fileHasExtOf(filename, imgExts);
}

export function hasExcelFileExt(filename: string) {
    return fileHasExtOf(filename, excelExts);
}

export function hasWordFileExt(filename: string) {
    return fileHasExtOf(filename, wordExts);
}

export function hasZipExt(filename: string) {
    return fileHasExtOf(filename, zipExts);
}

export function getLabel(opts: SelectItem[], value: any): string {
    if (value) {
        return opts.filter((item) => item.value == value)[0].label;
    }
    return "";
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function urlPath(urlTree: UrlTree | string): string {
    if (typeof urlTree == "string") {
        let serializer = new DefaultUrlSerializer();
        urlTree = serializer.parse(urlTree) as UrlTree;
    }
    let urlPath = "";
    for (let urlSegement of urlTree.root.children[PRIMARY_OUTLET].segments) {
        urlPath += "/" + urlSegement.path;
    }
    return urlPath;
}

export function urlPathSegments(urlTree: UrlTree | string): string[] {
    if (typeof urlTree == "string") {
        let serializer = new DefaultUrlSerializer();
        urlTree = serializer.parse(urlTree) as UrlTree;
    }
    let urlSegments = [];
    for (let urlSegement of urlTree.root.children[PRIMARY_OUTLET].segments) {
        urlSegments = [...urlSegments, urlSegement.path];
    }
    return urlSegments;
}

export function uniqueId() {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
}

export function errorsToMessages(errors, settings: Message = null): Message[] {
    let messageSettings = getSettings(settings);
    let arrErrMessages = [];
    for (let error of errors) {
        if (typeof error === "string") {
            arrErrMessages = [...arrErrMessages, { ...messageSettings, detail: error }];
            continue;
        }
        for (let errorIndex in error) {
            arrErrMessages = [...arrErrMessages, { ...messageSettings, detail: error[errorIndex] }];
        }
    }
    return arrErrMessages;
}

function getSettings(settings: Message): Message {
    let messageSettings = { severity: "error", summary: "" };
    if (settings) {
        messageSettings = { ...messageSettings, ...settings };
    }
    // console.log(messageSettings);
    return messageSettings;
}

export function createArrOfSelectItems(options, valueToInt = true): SelectItem[] {
    return Object.keys(options).map((key) => {
        return {
            value: valueToInt ? parseInt(key) : key,
            label: options[key],
        };
    });
}

export function createArrOfSelectItemsByProperty<T>(objects: T[], labelProperty: string, valueProperty: string): SelectItem<T>[] {
    return objects.map((object: T) => {
        return {
            label: object[labelProperty],
            value: object[valueProperty],
        };
    });
}

export function calendarYearRange(pastNrYears: number = 1, commingNrYears: number = 15) {
    return new Date().getFullYear() - pastNrYears + ":" + (new Date().getFullYear() + commingNrYears);
}

export enum HttpResponseStatusCodes {
    OK = 200,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    UnprocessableEntity = 422,
    InternalServerError = 500,
}

export enum AuthSide {
    BUYER = "buyer",
    SUPPLIER = "supplier",
    BACKOFFICE = "backoffice",
}

export class DropdownItem {
    id: string;
    name: string;
}

export function deepCopy(object) {
    return JSON.parse(JSON.stringify(object));
}

export enum MouseEventButton {
    LEFT = 0,
    MIDDLE = 1,
    RIGHT = 2,
}
