import { Directive, ElementRef, } from '@angular/core';
import { NgControl, NgModel, FormControlName, FormControl } from '@angular/forms';

@Directive({
    selector: '[appNativeElementInjector]',
})
export class NativeElementInjectorDirective {

    constructor(private el: ElementRef, private control: NgControl) {
        setTimeout(() => {
            let nodeInput = el.nativeElement;
            // console.log(el.nativeElement.nodeName);
            if (el.nativeElement.nodeName != "INPUT" && el.nativeElement.nodeName != "TEXTAREA") {
                nodeInput = el.nativeElement.querySelector('input');
            }
            (<any>control.control).nativeElement = nodeInput;
        });
    }

}
