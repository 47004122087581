import { Component, Injector, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import { HomepagesGuard } from "../../route-guards/homepages.guard";
import { DynamicModuleData, HomepageDynamicLoadService } from "./homepage-dynamic-load.service";
import { TRANSLATION_FILE_PROVIDER_CONFIGS } from "@app/common/i18n/translation/translation-file-provider";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    @ViewChild("homepage", { read: ViewContainerRef }) containerRef;
    private homepageComponent: any;

    constructor(
        private router: Router,
        private homepageDynamicLoadService: HomepageDynamicLoadService,
        private _injector: Injector) { }

    ngOnInit() {
        // let loginModule = this.homepageDynamicLoadService.getLoginModule();
        this.homepageDynamicLoadService.dynamicLoad(null, (dynamicModule: DynamicModuleData) => {
            if (dynamicModule.component) {
                this.createHomepageInstance(dynamicModule);
            } else {
                this.homepageByRoute();
            }
        });
    }

    /**
     * @deprecated use homepageDynamicLoadService
     */
    private homepageByRoute() {
        console.warn("deprecated homepageByRoute use homepageDynamicLoadService instead");
        let hg = new HomepagesGuard();
        let moduleName = hg.getLoginModule();
        if (moduleName != "default") {
            this.router.navigate(["/login/" + moduleName]);
        }
    }

    // private getLoginModule() {
    //     let hostname = window.location.hostname;

    //     let arrHostname = hostname.split(".");
    //     if (arrHostname[0] == "dev" || arrHostname[0] == "local") {
    //         arrHostname = arrHostname.slice(1);
    //         hostname = arrHostname.join(".");
    //     }

    //     let moduleName = hostname.replace(/\./g, "-");
    //     return moduleName;
    // }

    private createHomepageInstance(dynamicModule: DynamicModuleData) {
        // console.log(this._injector.get(TRANSLATION_FILE_PROVIDER_CONFIGS));
        // console.log(dynamicModule.moduleRef.injector.get(TRANSLATION_FILE_PROVIDER_CONFIGS));
        let componentRef = this.containerRef.createComponent(dynamicModule.component, { environmentInjector: dynamicModule.moduleRef.injector, injector: this._injector });
        this.homepageComponent = componentRef.instance;
    }
}
