import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { validateForm } from "@app/common/helpers";
import { LoaderService } from "@app/common/services/loader.service";
import { UserSettingsService } from "@app/common/services/user-settings.service";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { HomepagesService } from "../homepages.service";
import { Step2Component } from "./step2/step2.component";
import { TranslationService } from "@app/common/i18n/translation/translation.service";

@Component({
    selector: "appDefaultLoginForm",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
    captchaSrc: string;
    captchaSrcFP: string;
    showForgotPassw: boolean = false;

    loginForm: UntypedFormGroup;
    forgotPassForm: UntypedFormGroup;

    @Input() forgotPassw: boolean = true;
    @Input() newSuppReg: boolean = true;
    @Input() loginWith: {[key: string]: boolean} = null;

    @Input() srcHelpBuyer: string;
    @Input() srcHelpBackoffice: string;
    @Input() srcHelpSupplier: string;

    @Input() hideBuyerTC: boolean = false;
    @Input() hideSupplierTC: boolean = false;

    @Input() customContentTemplate: TemplateRef<any>;

    @Input() manuals: any;
    @Input() locale: string;
    @Input() color: string;

    @Output() loaded = new EventEmitter();

    targetUrl: string;

    constructor(
        private loader: LoaderService,
        private homepagesService: HomepagesService,
        private fb: UntypedFormBuilder,
        private messageService: MessageService,
        public dialogService: DialogService,
        public changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        public userSettingsService: UserSettingsService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.targetUrl = this.activatedRoute.snapshot.queryParams.targetUrl;
        let error = this.activatedRoute.snapshot.queryParams.error;
        if (error) {
            this.handleError(error);
        }
        this.loginForm = this.fb.group({
            username: ["", Validators.required],
            password: ["", Validators.required],
        });
        this.homepagesService.index().subscribe((response) => {});
        this.userSettingsService.obsOnSetLocale$.subscribe(() => this.loader.stopLoader());
    }

    private handleError(error: string) {
        this.translationService.loadTranslations().subscribe(() => {
            this.messageService.add({
                severity: "error",
                detail: this.translationService.get(error),
            });
        });
    }

    onSubmit(formData = null) {
        if (formData && !validateForm(this.loginForm, true)) {
            return;
        }

        this.homepagesService.login(formData).subscribe((response) => {
            if (!response.status) {
                if (!this.loginForm.get("captchaCode")) {
                    if (response.showCaptcha) {
                        // sessionStorage.setItem('captcha', 'true');
                        this.addCaptcha(/*this.captchaHash*/);
                    }
                } else {
                    this.reloadCaptchaImg();
                }

                this.loginForm.controls.password.reset();
                this.loginForm.updateValueAndValidity();

                if (response.errorMsg) {
                    this.messageService.add({
                        severity: "error",
                        detail: response.errorMsg,
                        life: 3000,
                    });
                }

                this.changeDetectorRef.markForCheck();
                return;
            }

            Object.keys(this.loginForm.controls).forEach((field) => {
                const control = this.loginForm.get(field);
                (control as any).nativeElement.blur();
            });

            let srcHelp = { buyer: this.srcHelpBuyer, backoffice: this.srcHelpBackoffice, supplier: this.srcHelpSupplier };
            const ref = this.dialogService.open(Step2Component, {
                data: {
                    ...response.dataStep2,
                    formData,
                    srcHelp: srcHelp,
                    hideBuyerTC: this.hideBuyerTC,
                    hideSupplierTC: this.hideSupplierTC,
                    manuals: this.manuals,
                    targetUrl: this.targetUrl,
                },
                contentStyle: { "max-width": "800px", height: "600px", width: "90vw" },
            });

            ref.onClose.subscribe(() => {
                if (this.loginForm.get("captchaCode")) {
                    this.reloadCaptchaImg();
                }
            });
        });
    }

    showForgotPasswDlg(e) {
        e.preventDefault();
        this.forgotPassForm = this.fb.group({
            email_or_nickname: ["", [Validators.required]],
            captcha: ["", [Validators.required]],
        });
        this.reloadCaptchaImg(true, () => {
            this.showForgotPassw = true;
        });
    }

    reloadCaptchaImg(forgotPass = false, callback = null) {
        this.homepagesService.reloadCaptcha().subscribe((response) => {
            if (!forgotPass) {
                //captcha from login form
                this.loginForm.controls.captchaCode.setValidators(Validators.compose([Validators.required /*, captchaValidator(response.hash1)*/]));
                this.loginForm.get("captchaCode").reset();
                this.loginForm.controls.captchaCode.updateValueAndValidity();
                this.captchaSrc = response.url;
            } else {
                //captcha from forgot password dialog
                this.forgotPassForm.controls.captcha.setValidators(Validators.compose([Validators.required /*, captchaValidator(response.hash1)*/]));
                this.forgotPassForm.get("captcha").reset();
                this.forgotPassForm.controls.captcha.updateValueAndValidity();
                this.captchaSrcFP = response.url;
            }

            if (callback) {
                callback();
            }

            this.changeDetectorRef.markForCheck();
        });
    }

    addCaptcha() {
        this.loginForm.addControl("captchaCode", new UntypedFormControl("", [Validators.required]));
        this.captchaSrc = this.homepagesService.captchaUrl;
    }

    resetPassword(formData) {
        if (!validateForm(this.forgotPassForm, true)) {
            return;
        }

        this.homepagesService.resetPassword(formData).subscribe((response) => {
            let severity = "success";
            if (response.status) {
                this.showForgotPassw = false;
            } else {
                severity = "error";
                this.reloadCaptchaImg(true);
            }
            this.messageService.add({
                severity: severity,
                detail: response.msg,
                life: 10000,
            });
            this.changeDetectorRef.markForCheck();
        });
    }

    doLoginWith(integrationName: string) {
        window.location.href = `/users/oauth/${integrationName}/login`;
    }
}
