<p-dropdown
    *ngIf="languages?.length > 1"
    [options]="languages"
    [(ngModel)]="locale"
    optionLabel="value"
    optionValue="locale"
    styleClass="headerLanguage"
    (onChange)="changeLanguage($event)"
    appendTo="body"
>
    <ng-template let-item pTemplate="selectedItem">
        <div class="clearfix">
            <div class="float-start pe-1">
                <span class="fi fi-{{country}}"></span>
            </div>
        </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
        <div class="clearfix" style="width: 140px;">
            <div class="float-start pe-1">
                <span class="fi fi-{{item.country}}"></span>
            </div>
            <div class="float-start">{{item.value}}</div>
        </div>
    </ng-template>
</p-dropdown>
