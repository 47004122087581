import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { Language } from "@app/common/layout/language";
import { PageService } from "@app/common/services/page.service";
import { HomepagesService } from "../../homepages.service";
import { UserSettingsService } from "@app/common/services/user-settings.service";

@Component({
    selector: "appLanguageSwitcher",
    templateUrl: "./language-switcher.component.html",
    styleUrls: ["./language-switcher.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent implements OnInit {
    languages: Language[];
    locale: string;

    constructor(
        private homepagesService: HomepagesService,
        private pageService: PageService,
        private changeDetectorRef: ChangeDetectorRef,
        private userSettingsService: UserSettingsService
    ) {}

    ngOnInit() {
        this.homepagesService.getLanguages().subscribe((response) => {
            this.languages = response.languages;
            if (!sessionStorage.getItem("locale")) {
                sessionStorage.setItem("locale", response.locale);
            }
            this.locale = sessionStorage.getItem("locale");
            this.userSettingsService.locale = this.locale;
            this.changeDetectorRef.markForCheck();
        });
    }

    changeLanguage(event) {
        sessionStorage.setItem("locale", event.value);
        let idLanguage = this.languages.find((item) => item.locale == event.value)?.key;
        this.pageService.changeLanguage(idLanguage).subscribe((response) => {
            window.location.reload();
        });
    }

    get country(): string {
        return this.languages.find((item) => item.locale == this.locale)?.country;
    }
}
