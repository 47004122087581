import { Injectable } from "@angular/core";

import { TextsService } from "./texts.service";

@Injectable({
    providedIn: "root",
})
export class ValidationTextsService extends TextsService {
    constructor() {
        super();
    }

    setTextsByLanguage(translations: { [key: string]: string }) {
        this.texts = [
            { key: "required", value: translations["general.mandatory_field"] },
            { key: "maxlength", value: translations["general.max_chars_allowed"] },
            { key: "minlength", value: translations["general.min_chars"] },
            { key: "format", value: translations["general.validation_invalid_number"] },
            { key: "min", value: translations["general.min_val"] },
            { key: "max", value: translations["general.max_val"] },
            { key: "email", value: translations["general.err_invalid_email"] },
            { key: "validatePattern", value: translations["general.err_invalid_link"] },
            { key: "pattern", value: translations["general.err_invalid_pattern"] },
            { key: "incorrectSecCode", value: translations["general.sec_code_incorrect"] },
            { key: "incorrectNumbers", value: translations["general.incorrect_numbers"] },
            { key: "validateStep", value: translations["general.validate_step"] },
        ];
    }
}
