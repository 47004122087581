import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplateValidatorsComponent } from './template-validators.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [TemplateValidatorsComponent],
  declarations: [TemplateValidatorsComponent]
})
export class TemplateValidatorsModule { }
