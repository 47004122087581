import { Component, OnInit, ViewChild} from '@angular/core';

import { PostItem } from './postItem';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})


export class HistoryComponent implements OnInit {

  url: string;
  posts: PostItem[];

  submit: boolean = false;

  @ViewChild("frmHistory") frmHistory;

  constructor() { }

  ngOnInit() {
  }

  //submit() {
    //console.debug(this.frmHistory);

    //(this.frmHistory as any).submitted = true;
    //this.frmHistory.ngSubmit.emit(true);


    //if(this.frmHistory) {
      //console.debug(this.frmHistory);
      //console.debug(this.frmHistory.nativeElement.action);
    //}

    //this.frmHistory.nativeElement.submit();
  //}

  ngAfterViewChecked() {
    if(this.submit) {
      this.submit = false;
      this.frmHistory.nativeElement.submit();
    }
  }
}
